import React, { FunctionComponent } from 'react'
import { Tooltip } from 'react-tippy'
import { tooltipStyles } from './ColumnTooltip.styles'

interface IColumnTooltipProps {
  children: React.ReactNode
  tooltipText: string
  additionalClass?: string
}

const ColumnTooltip: FunctionComponent<IColumnTooltipProps> = ({
  children,
  tooltipText,
  additionalClass = ''
}) => {
  return (
    <div style={tooltipStyles} className={additionalClass}>
      <Tooltip
        theme="light"
        title={tooltipText}
        position="bottom"
        trigger="mouseenter"
        arrow={true}
        style={{ width: '100%' }}
      >
        {children}
      </Tooltip>
    </div>
  )
}

export default ColumnTooltip
