import React from 'react'
import {
  ClinTableBodyCell,
  ClinTableRow,
  ClinPatientTableHeaderCell
} from '../../components/ClinTable/ClinTable'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { OrderSummaryDto } from '../../types/swaggerTypes'
import { ClinSpinner } from '../../components/ClinSpinner'
import {
  TableWrapper,
  StyledClinTable,
  FixedWidthCell,
  StyledClinTableOrderToggleWrapper,
  ActionCell,
  StyledTooltipSearchWrapper
} from './OrdersTable.styles'
import { ClinTableOrderToggle } from '../../components/ClinTableOrderToggle'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ActionDropdownProvider } from '../../pages/OrderPages/Orders/ActionsColumn/ActionsColumn'
import { useNewFeaturesList } from '../../hooks/useNewFeaturesList/useNewFeaturesList'
import {
  NewFeatureElements,
  PageNames
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'

interface OrdersTableProps {
  orders: OrderSummaryDto[]
  isLoading: boolean
  columns: Array<{
    name: string
    viewName: string
    width: string
    isSortable?: boolean
    renderValue?: (order: OrderSummaryDto) => React.ReactNode
  }>
  isEmptySearch: boolean
  selectedSortColumn?: any
  selectedSortDirection?: SortDirectionType
  supportContact: any
  handleToggleSort: (columnName: string | undefined) => void
  handleRowClicked: (orderId: string) => void
  handleTabSelected: (tabIndex: number) => void
  handleToggleOrderRow: (orderNumber: string) => void
}

export const OrdersTable: React.FC<OrdersTableProps> = ({
  orders,
  isLoading,
  columns,
  selectedSortColumn,
  selectedSortDirection,
  handleToggleSort,
  handleRowClicked
}) => {
  const { newFeaturesList, displayNewFeature, displayNewFeatureGlowEffect } =
    useNewFeaturesList(PageNames.Orders)
  const isShowFeatureOrderFirst =
    displayNewFeature && displayNewFeature(NewFeatureElements.OrdersFirst)

  if (isLoading) {
    return (
      <StyledSpinnerContainer>
        <ClinSpinner size={ClinTheme.space[7]} />
      </StyledSpinnerContainer>
    )
  }

  if (orders.length === 0) {
    return (
      <ClinText
        variant={TypographyVariant.H3}
        textAlign="center"
        marginTop={35}
      >
        No orders found.
      </ClinText>
    )
  }

  return (
    <ActionDropdownProvider>
      <TableWrapper>
        <StyledClinTable
          borderColapseSeparate={true}
          borderSpacing="0 8px"
          tableHeader={() =>
            columns.map((column, index) => (
              <ClinPatientTableHeaderCell
                key={index}
                background={ClinTheme.colors.lightGrey}
                style={{ cursor: 'default' }}
              >
                <FixedWidthCell width={column.width}>
                  {column.isSortable ? (
                    <StyledClinTableOrderToggleWrapper>
                      <ClinTableOrderToggle
                        sortDirection={
                          selectedSortColumn.name === column.name
                            ? selectedSortDirection
                            : SortDirectionType.None
                        }
                        onClick={() => handleToggleSort(column.name)}
                        isUppercase={false}
                      >
                        {column.viewName}
                      </ClinTableOrderToggle>
                    </StyledClinTableOrderToggleWrapper>
                  ) : (
                    <StyledClinTableOrderToggleWrapper>
                      <ClinTableOrderToggle hideSortIcon={true}>
                        {column.viewName}
                      </ClinTableOrderToggle>
                    </StyledClinTableOrderToggleWrapper>
                  )}
                </FixedWidthCell>
              </ClinPatientTableHeaderCell>
            ))
          }
          className="TableOverflowVisible"
        >
          <StyledTooltipSearchWrapper>
            {newFeaturesList &&
              displayNewFeature(NewFeatureElements.OrdersFirst) && (
                <ClinNewFeatureTooltipContainer
                  feature={newFeaturesList[NewFeatureElements.OrdersFirst]}
                  openTooltipInitially={
                    !newFeaturesList[NewFeatureElements.OrdersFirst]
                      ?.isCancelled
                  }
                  disableDismiss={true}
                  tooltipWidth="242px"
                  total={3}
                  current={1}
                />
              )}
          </StyledTooltipSearchWrapper>
          {orders.map((order: OrderSummaryDto, rowIndex) => {
            const isHighlighted =
              isShowFeatureOrderFirst &&
              order.orderNumber === orders[0]?.orderNumber

            return (
              <ClinTableRow
                background={ClinTheme.colors.white}
                key={rowIndex}
                onRowClick={() => handleRowClicked(order.orderNumber || '')}
                style={{
                  height: '62px',
                  position: isHighlighted ? 'relative' : 'unset',
                  zIndex: isHighlighted ? 1 : 0,
                  boxShadow:
                    newFeaturesList &&
                    displayNewFeatureGlowEffect &&
                    (displayNewFeatureGlowEffect?.(
                      NewFeatureElements.OrdersFirst
                    ) ||
                      displayNewFeatureGlowEffect?.(
                        NewFeatureElements.OrdersFirst
                      ))
                      ? '0 0 14px #fff'
                      : ''
                }}
              >
                {columns.map((column, index) => (
                  <ClinTableBodyCell
                    key={index}
                    style={{ padding: '11px 20px 11px 16px' }}
                  >
                    {column.name === 'actions' ? (
                      <ActionCell width={column.width}>
                        {column.renderValue ? (
                          column.renderValue(order)
                        ) : (
                          <ClinText
                            variant={TypographyVariant.SmallUI}
                            lineHeight={ClinTheme.lineHeights.largeParagraph}
                          >
                            {(order as any)[column.name]}
                          </ClinText>
                        )}
                      </ActionCell>
                    ) : (
                      <FixedWidthCell width={column.width}>
                        {column.renderValue ? (
                          column.renderValue(order)
                        ) : (
                          <ClinText
                            variant={TypographyVariant.SmallUI}
                            lineHeight={ClinTheme.lineHeights.largeParagraph}
                          >
                            {(order as any)[column.name]}
                          </ClinText>
                        )}
                      </FixedWidthCell>
                    )}
                  </ClinTableBodyCell>
                ))}
              </ClinTableRow>
            )
          })}
        </StyledClinTable>
      </TableWrapper>
    </ActionDropdownProvider>
  )
}
