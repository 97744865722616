import React from 'react'
import { ClinText } from '../../../../components/ClinText'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinIcon } from '../../../../components/ClinIcon'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import {
  PatientOrderStatus,
  patientOrderStatusTooltipTranslation,
  patientOrderStatusTranslation,
  mapToPatientOrderStatus
} from '../../../Patients/PatientDashboard/Patient.model'
import StatusTooltip from '../../../Patients/PatientDashboard/StatusTooltip'
import { t } from 'i18next'
import {
  StyledStatusWrapperForOrders,
  StyledTrackOrderWrapper
} from '../Orders.styles'
import { OrderStatus } from '../../../../types/OrderStatus'

interface OrderStatusComponent {
  orderStatus: string
  handleTrackOrder?: () => void
}

// Status mappings for tracking functionality
const statusMappings: { [key in string]: { showTrackIcon: boolean } } = {
  'In Transit': { showTrackIcon: true },
  [OrderStatus.OutForDelivery]: { showTrackIcon: true },
  [OrderStatus.Out_For_Delivery]: { showTrackIcon: true },
  [OrderStatus.FailedAttempt]: { showTrackIcon: true },
  [OrderStatus.Failed_Attempt]: { showTrackIcon: true },
  [OrderStatus.AvailableForPickup]: { showTrackIcon: true },
  [OrderStatus.Available_For_Pickup]: { showTrackIcon: true },
  [OrderStatus.Delivered]: { showTrackIcon: true },
  [OrderStatus.FailedDelivery]: { showTrackIcon: true },
  [OrderStatus.Failed_Delivery]: { showTrackIcon: true },
  [OrderStatus.Submitted]: { showTrackIcon: false },
  [OrderStatus.Processing]: { showTrackIcon: false },
  [OrderStatus.Pending]: { showTrackIcon: false },
  [OrderStatus.Shipped]: { showTrackIcon: false },
  [OrderStatus.UnderMedicalReview]: { showTrackIcon: false },
  [OrderStatus.HealthAuthorityReview]: { showTrackIcon: false },
  [OrderStatus.MedicalReviewDeclined]: { showTrackIcon: false },
  [OrderStatus.HealthAuthorityDeclined]: { showTrackIcon: false },
  [OrderStatus.Cancelled]: { showTrackIcon: false },
  [OrderStatus.On_Hold]: { showTrackIcon: false },
  [OrderStatus.OnHold]: { showTrackIcon: false },
  [OrderStatus.Expired]: { showTrackIcon: false }
}

// Special status mappings for non-standard status values
const specialStatusMappings: { [key: string]: PatientOrderStatus } = {
  Failed_Delivery: PatientOrderStatus.FailedDelivery,
  'In Transit': PatientOrderStatus.InTransit
}

/**
 * Maps status strings to PatientOrderStatus, handling special cases
 * @param status Original status string
 * @returns Mapped PatientOrderStatus
 */
export const mapSpecialOrderStatus = (status: string): PatientOrderStatus => {
  // Check if status is in special mappings
  if (status in specialStatusMappings) {
    return specialStatusMappings[status]
  }

  // Fall back to standard mapping
  return mapToPatientOrderStatus(status) as PatientOrderStatus
}

const StatusColumn: React.FC<OrderStatusComponent> = ({
  orderStatus,
  handleTrackOrder
}) => {
  let statusComponent
  let showTableInTab = false

  // Get the original status before mapping for track icon check
  const originalStatus = orderStatus
  const mappedStatus = mapSpecialOrderStatus(originalStatus)

  // Check if we should show the track icon
  const showTrackIcon = statusMappings[originalStatus]?.showTrackIcon

  const getTranslation = (status: string): string => {
    const translationKey =
      patientOrderStatusTranslation[
        status as keyof typeof patientOrderStatusTranslation
      ]
    return translationKey ? t(translationKey) : status
  }

  const getTooltipTranslation = (status: string): string => {
    const tooltipKey =
      patientOrderStatusTooltipTranslation[
        status as keyof typeof patientOrderStatusTooltipTranslation
      ]
    return tooltipKey ? t(tooltipKey) : ''
  }

  const renderStatusContent = (
    backgroundColor: string,
    textColor: string,
    showTooltip: boolean = true
  ) => {
    const content = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ClinText
          whiteSpace="nowrap"
          fontWeight={ClinTheme.fontWeights.medium}
          color={textColor}
          textAlign="center"
          fontSize={
            showTableInTab ? ClinTheme.fontSizes[0] : ClinTheme.fontSizes[1]
          }
          lineHeight={showTableInTab ? '2' : ClinTheme.lineHeights.small}
          display="flex"
        >
          {getTranslation(mappedStatus)}
          {showTrackIcon && handleTrackOrder && (
            <StyledTrackOrderWrapper
              onClick={(event) => {
                event.stopPropagation()
                handleTrackOrder()
              }}
            >
              <ClinIcon
                iconName={ClinIconPathName.DeliveryAus}
                iconHeight={10}
                viewBox="0 0 24 18"
                iconFill={ClinTheme.colors.tealishBlue}
              />
              <ClinText
                as="span"
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.tealishBlue}
              >
                Track
              </ClinText>
            </StyledTrackOrderWrapper>
          )}
        </ClinText>
      </div>
    )

    return (
      <StyledStatusWrapperForOrders color={backgroundColor}>
        {showTooltip ? (
          <StatusTooltip tooltipText={getTooltipTranslation(mappedStatus)}>
            {content}
          </StatusTooltip>
        ) : (
          content
        )}
      </StyledStatusWrapperForOrders>
    )
  }

  switch (mappedStatus) {
    case PatientOrderStatus.PatientDiscontinued:
    case PatientOrderStatus.ProgramClosed:
    case PatientOrderStatus.HealthAuthorityDeclined:
    case PatientOrderStatus.MedicalReviewDeclined:
    case PatientOrderStatus.Cancelled:
      statusComponent = renderStatusContent(
        'rgba(117, 117, 117, 0.1)',
        ClinTheme.colors.darkGrey
      )
      break

    case PatientOrderStatus.BeginOrder:
    case PatientOrderStatus.IncompletePAF:
    case PatientOrderStatus.IncompleteCheckout:
    case PatientOrderStatus.ReadyForResupply:
      statusComponent = (
        <span style={{ position: 'relative' }}>
          {renderStatusContent(
            'rgba(0, 67, 206, 0.1)',
            ClinTheme.colors.tealishBlue
          )}
        </span>
      )
      break

    case PatientOrderStatus.InTransit:
    case PatientOrderStatus.OutForDelivery:
    case PatientOrderStatus.Available_for_Pickup:
    case PatientOrderStatus.FailedAttempt:
      statusComponent = renderStatusContent(
        'rgba(0, 162, 35, 0.1)',
        ClinTheme.colors.greenValid
      )
      break

    case PatientOrderStatus.Delivered:
      statusComponent = renderStatusContent(
        'rgba(0, 162, 35, 0.1)',
        ClinTheme.colors.greenValid,
        false
      )
      break

    case PatientOrderStatus.Pending:
    case PatientOrderStatus.Shipped:
    case PatientOrderStatus.Submitted:
    case PatientOrderStatus.Processing:
      statusComponent = renderStatusContent(
        'rgba(0, 162, 35, 0.1)',
        ClinTheme.colors.greenValid
      )
      break

    case PatientOrderStatus.UnderReview:
    case PatientOrderStatus.UnderMedicalReview:
    case PatientOrderStatus.HealthAuthorityReview:
    case PatientOrderStatus.FailedDelivery:
      statusComponent = renderStatusContent(
        'rgba(215, 106, 0, 0.10)',
        ClinTheme.colors.orangeWarn
      )
      break

    default:
      statusComponent = renderStatusContent(
        'rgba(117, 117, 117, 0.1)',
        ClinTheme.colors.darkGrey,
        false
      )
      break
  }

  return statusComponent
}

export default StatusColumn
