import React, { useEffect, useState } from 'react'
import {
  StyledPatientSearchContentWrapper,
  StyledPatientSearchDivWrapper,
  StyledPatientSearchWrapper
} from '../../Patients/PatientSearch.styles'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../../components/ClinPagination/ClinPagination.model'
import { NewFeatureElements } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { Col, Row } from 'react-grid-system'
import { ClinPagination } from '../../../components/ClinPagination'
import { OrdersTable } from '../../../features/OrdersTable'
import { ClinTheme } from '../../../ClinTheme'
import { CountryDto, OrderSummaryDto } from '../../../types/swaggerTypes'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { OrderColumn, orderColumns } from '../..'
import { RecentSkuCardsContainer } from '../../../features/RecentSkuCards'
import {
  StyledFilterCounter,
  StyledFilterIconContainer,
  StyledGrayWrapper,
  StyledMobileOrderSearch,
  StyledTitleText
} from './Orders.styles'
import { useTranslation } from 'react-i18next'
import { IBanner } from '../../../types/IBanner'
import { INewFeature } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import OrderFilters, { FilterOption } from './OrderFilters/OrderFilters'
import { BasicSearch } from '../../../features/Search/BasicSearch'
import {
  useNewFeatures,
  removeFeature,
  ActionType
} from '../../../context/newFeatures'
import { StyledTooltipSearchWrapper } from '../../Patients/PatientDashboard/PatientDashboard.styles'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinButton } from '../../../components/ClinButton'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import {
  setFeatureToViewed,
  addNewFeatureClosedEvent,
  getNewFeatureEventDescription
} from '../../../services/NewFeaturesService'

interface IDashboardProps {
  isLoading: boolean
  isMaUser: boolean
  newFeaturesList?: { [id: string]: INewFeature }
  userPreferredName?: string
  orders: OrderSummaryDto[]
  pagination: IPagination
  selectedSortColumn?: OrderColumn
  selectedSortDirection?: SortDirectionType
  selectedTabIndex?: number
  initialQuery?: string
  isEmptySearch: boolean
  /** The banner from SquidEx */
  banner?: IBanner
  supportContact: CountryDto
  showMyOrders: boolean
  handlePageClicked: (selectedPageIndex: number) => void
  handleTabSelected: (selectedTabIndex: number) => void
  handleToggleSort: (columnName: string | undefined) => void
  handleRowClicked: (selectedOrderId: string) => void
  handleToggleOrderRow: (orderNumber: string) => void
  handlePageSizeChange: (pageSize: number) => void
  handleOnSearch: (query: string) => void
  handleOnSubmit: () => void
  handleMyOrders?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChange: (query: string) => void
  selectedFilter: FilterOption[]
  handleFilterChange: (filter: FilterOption[]) => void
  handleShowMyOrders: (event: React.ChangeEvent<HTMLInputElement>) => void
  displayNewFeature?: (feature: NewFeatureElements) => boolean | undefined
  displayNewFeatureGlowEffect?: (
    feature: NewFeatureElements
  ) => boolean | undefined
  showTableInTab?: boolean
}

export const Orders: React.FC<IDashboardProps> = ({
  isLoading,
  isMaUser,
  orders,
  pagination,
  selectedSortColumn,
  selectedSortDirection,
  initialQuery,
  isEmptySearch,
  supportContact,
  handlePageClicked,
  handleTabSelected,
  handleToggleSort,
  handleRowClicked,
  handleToggleOrderRow,
  handlePageSizeChange,
  handleOnChange,
  handleFilterChange,
  selectedFilter,
  displayNewFeature,
  displayNewFeatureGlowEffect,
  showTableInTab,
  newFeaturesList
}) => {
  const { t } = useTranslation()

  const [, featuresDispatch] = useNewFeatures()
  const handleSort = (columnName: string | undefined) => {
    handleToggleSort(columnName)
  }

  const [, newFeaturesDispatch] = useNewFeatures()

  const handleCloseFeatureHighlight = (feature: NewFeatureElements) => {
    const viewedFeature = newFeaturesList?.[feature]
    viewedFeature &&
      setFeatureToViewed(viewedFeature.id, viewedFeature.version, true)
        .then(() => {
          newFeaturesDispatch({
            type: ActionType.UpdateNewFeatures,
            feature: { id: viewedFeature.id }
          })
          addNewFeatureClosedEvent(
            getNewFeatureEventDescription(viewedFeature.id)
          )
        })
        .catch((error: any) => {})
  }

  const [isFeatureVisible] = useState(false)

  const [isMobile, setIsMobile] = React.useState<boolean>(false)

  const [isShowing, setIsShowing] = React.useState<boolean>(false)

  const updateIsMobile = () => {
    setIsMobile(
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) <= ClinTheme.breakpoints[2]
    )
  }

  const listener = () => {
    updateIsMobile()
  }

  const handleBasicSearchClick = () => {
    if (newFeaturesList && newFeaturesList[NewFeatureElements.OrdersSearch]) {
      // Remove focus from input
      const input = document.querySelector(
        '.clin-search-input input'
      ) as HTMLInputElement
      input && input.blur()
      removeFeature(
        featuresDispatch,
        newFeaturesList[NewFeatureElements.OrdersSearch].id
      )
    }
  }

  useEffect(() => {
    updateIsMobile()
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [isShowing, isMobile])

  const getBadgeValue = (value: number): string => {
    return value > 99 ? '99+' : value.toString()
  }

  return (
    <>
      <StyledPatientSearchDivWrapper
        background={ClinTheme.colors.white}
        showtableintab={showTableInTab?.toString()}
      >
        {isMobile ? (
          <StyledPatientSearchWrapper
            showtableintab={showTableInTab?.toString()}
          >
            <Col md={12} offset={{ md: 2 }} style={{ marginLeft: '0px' }}>
              <StyledPatientSearchContentWrapper>
                {!showTableInTab && (
                  <>
                    {newFeaturesList &&
                      displayNewFeature?.(NewFeatureElements.OrdersSearch) && (
                        <StyledTooltipSearchWrapper>
                          <ClinNewFeatureTooltipContainer
                            feature={
                              newFeaturesList?.[NewFeatureElements.OrdersSearch]
                            }
                            openTooltipInitially={true}
                            showFeatureFromTable={true}
                            disableDismiss={true}
                            tooltipWidth="242px"
                            total={3}
                            current={2}
                          ></ClinNewFeatureTooltipContainer>
                        </StyledTooltipSearchWrapper>
                      )}
                    <StyledTitleText>
                      <ClinText
                        color={ClinTheme.colors.black}
                        fontWeight={ClinTheme.fontWeights.bold}
                        variant={TypographyVariant.H3}
                        marginTop={'-16px'}
                      >
                        {t('orders:search_order_title')}
                      </ClinText>
                    </StyledTitleText>
                    <StyledFilterIconContainer>
                      <ClinText
                        color={ClinTheme.colors.black}
                        fontWeight={ClinTheme.fontWeights.medium}
                        variant={TypographyVariant.H5}
                      >
                        {t('patient_detail:filter_by')}
                      </ClinText>
                      <ClinButton
                        style={{
                          padding: '0px',
                          borderStyle: 'none',
                          borderColor: 'unset',
                          backgroundColor: 'unset',
                          color: isShowing
                            ? ClinTheme.colors.primary
                            : ClinTheme.colors.primaryMid,
                          marginLeft: '2px'
                        }}
                        onClick={() => setIsShowing(!isShowing)}
                      >
                        <ClinIcon
                          style={{ height: '32px', width: '32px' }}
                          iconName={
                            isShowing
                              ? ClinIconPathName.Cross
                              : ClinIconPathName.Filter
                          }
                        />
                        {selectedFilter?.length > 0 && !isShowing && (
                          <StyledFilterCounter className="filter-items">
                            {getBadgeValue(selectedFilter.length)}
                          </StyledFilterCounter>
                        )}
                      </ClinButton>
                    </StyledFilterIconContainer>
                  </>
                )}
              </StyledPatientSearchContentWrapper>
            </Col>
            {isShowing && (
              <OrderFilters
                selectedFilter={selectedFilter}
                isMobile={true}
                onFilterChange={handleFilterChange}
              />
            )}
            <StyledMobileOrderSearch>
              <BasicSearch
                boxShadow={
                  newFeaturesList &&
                  (displayNewFeatureGlowEffect?.(
                    NewFeatureElements.PatientsSearch
                  ) ||
                    displayNewFeatureGlowEffect?.(
                      NewFeatureElements.OrdersSearch
                    ))
                    ? '0 0 5px #fff'
                    : ''
                }
                zIndex={
                  !isFeatureVisible &&
                  newFeaturesList &&
                  (displayNewFeatureGlowEffect?.(
                    NewFeatureElements.PatientsSearch
                  ) ||
                    displayNewFeatureGlowEffect?.(
                      NewFeatureElements.OrdersSearch
                    ))
                    ? 3
                    : 0
                }
                borderRadious={showTableInTab ? '6' : '20'}
                height={showTableInTab ? `${ClinTheme.space[5]}px` : undefined}
                textIndent={showTableInTab ? ClinTheme.space[4] : undefined}
                fontSize={ClinTheme.fontSizes[1]}
                iconHeight={
                  showTableInTab ? `${ClinTheme.space[3]}px` : undefined
                }
                iconSize={
                  showTableInTab ? `${ClinTheme.space[3]}px` : undefined
                }
                marginLeft={1}
                iconColor={
                  showTableInTab
                    ? ClinTheme.colors.darkGrey
                    : ClinTheme.colors.primary
                }
                iconStyle={
                  showTableInTab
                    ? {
                        position: 'absolute',
                        left: ClinTheme.space[2],
                        top: '50%',
                        transform: 'translateY(-50%)'
                      }
                    : undefined
                }
                initialQuery={initialQuery}
                placeholder={t('orders:order_search_placeholder')}
                debounceTimeMs={500}
                handleOnChange={(query) => {
                  handleOnChange?.(query)
                }}
                handleClick={handleBasicSearchClick}
              />
            </StyledMobileOrderSearch>
          </StyledPatientSearchWrapper>
        ) : (
          <StyledPatientSearchWrapper
            showtableintab={showTableInTab?.toString()}
          >
            <Col md={8} offset={{ md: 2 }} style={{ marginLeft: '0px' }}>
              <StyledPatientSearchContentWrapper>
                {!showTableInTab && (
                  <>
                    {newFeaturesList &&
                      displayNewFeature &&
                      displayNewFeature(NewFeatureElements.OrdersSearch) && (
                        <StyledTooltipSearchWrapper>
                          <ClinNewFeatureTooltipContainer
                            feature={
                              newFeaturesList &&
                              newFeaturesList[NewFeatureElements.OrdersSearch]
                            }
                            openTooltipInitially={
                              !newFeaturesList[NewFeatureElements.OrdersSearch]
                                ?.isCancelled
                            }
                            showFeatureFromTable={true}
                            disableDismiss={true}
                            tooltipWidth="242px"
                            total={3}
                            current={2}
                          ></ClinNewFeatureTooltipContainer>
                        </StyledTooltipSearchWrapper>
                      )}

                    <StyledTitleText>
                      <ClinText
                        color={ClinTheme.colors.black}
                        fontWeight={ClinTheme.fontWeights.bold}
                        variant={TypographyVariant.H4}
                        marginRight={'22px'}
                        marginTop={'0px'}
                        marginBottom={'0px'}
                      >
                        {t('orders:search_order_title')}
                      </ClinText>
                    </StyledTitleText>
                  </>
                )}

                <BasicSearch
                  boxShadow={
                    newFeaturesList &&
                    displayNewFeatureGlowEffect &&
                    (displayNewFeatureGlowEffect?.(
                      NewFeatureElements.PatientsSearch
                    ) ||
                      displayNewFeatureGlowEffect?.(
                        NewFeatureElements.OrdersSearch
                      ))
                      ? '0 0 5px #fff'
                      : ''
                  }
                  zIndex={
                    !isFeatureVisible &&
                    newFeaturesList &&
                    displayNewFeatureGlowEffect &&
                    (displayNewFeatureGlowEffect?.(
                      NewFeatureElements.PatientsSearch
                    ) ||
                      displayNewFeatureGlowEffect?.(
                        NewFeatureElements.OrdersSearch
                      ))
                      ? 3
                      : 0
                  }
                  borderRadious={showTableInTab ? '6' : '20'}
                  height={
                    showTableInTab ? `${ClinTheme.space[5]}px` : undefined
                  }
                  textIndent={showTableInTab ? ClinTheme.space[4] : undefined}
                  fontSize={ClinTheme.fontSizes[1]}
                  maxWidth={showTableInTab ? '289px' : '77%'}
                  iconHeight={
                    showTableInTab ? `${ClinTheme.space[3]}px` : undefined
                  }
                  iconSize={
                    showTableInTab ? `${ClinTheme.space[3]}px` : undefined
                  }
                  marginLeft={1}
                  iconColor={
                    showTableInTab
                      ? ClinTheme.colors.darkGrey
                      : ClinTheme.colors.primary
                  }
                  iconStyle={
                    showTableInTab
                      ? {
                          position: 'absolute',
                          left: ClinTheme.space[2],
                          top: '50%',
                          transform: 'translateY(-50%)'
                        }
                      : undefined
                  }
                  initialQuery={initialQuery}
                  placeholder={
                    showTableInTab
                      ? t('orders:order_search_placeholder')
                      : t('orders:order_search_placeholder')
                  }
                  debounceTimeMs={500}
                  handleOnChange={(query) => {
                    handleOnChange && handleOnChange(query)
                  }}
                  handleClick={handleBasicSearchClick}
                />
              </StyledPatientSearchContentWrapper>
            </Col>
          </StyledPatientSearchWrapper>
        )}
      </StyledPatientSearchDivWrapper>

      <StyledGrayWrapper>
        <ClinPageContentFrame>
          {!isMobile && (
            <OrderFilters
              selectedFilter={selectedFilter}
              onFilterChange={handleFilterChange}
              handleCloseFeatureHighlight={handleCloseFeatureHighlight}
              isMobile={false}
            />
          )}
          <ClinSpacer
            height={ClinTheme.space[5]}
            hasBorder={true}
            width="1282px"
            top="125%"
          />
          <OrdersTable
            isLoading={isLoading}
            orders={orders}
            columns={orderColumns}
            isEmptySearch={isEmptySearch}
            selectedSortColumn={selectedSortColumn}
            selectedSortDirection={selectedSortDirection}
            supportContact={supportContact}
            handleToggleSort={handleSort}
            handleRowClicked={(orderId: string) => handleRowClicked(orderId)}
            handleTabSelected={(tabIndex: number) =>
              handleTabSelected(tabIndex)
            }
            handleToggleOrderRow={(orderNumber: string) =>
              handleToggleOrderRow(orderNumber)
            }
          />

          <Row>
            <Col>
              {!isLoading &&
                orders &&
                orders.length > 0 &&
                pagination &&
                pagination.total > 0 && (
                  <ClinPagination
                    currentPage={getCurrentPage(pagination)}
                    totalPages={getTotalPages(pagination)}
                    pageSize={pagination.take}
                    handlePageSelected={(pageIndex) =>
                      handlePageClicked(pageIndex)
                    }
                    handlePageSizeChange={handlePageSizeChange}
                  />
                )}
            </Col>
          </Row>
          {!isMaUser && (
            <>
              <ClinSpacer
                hasBorder={true}
                height={ClinTheme.space[7]}
                width="1282px"
              />
              <RecentSkuCardsContainer />
            </>
          )}
          <ClinSpacer />
        </ClinPageContentFrame>
      </StyledGrayWrapper>
    </>
  )
}
