export enum OrderStatus {
  Submitted = 'Submitted', // Maps to backend: Submitted
  Processing = 'Processing', // Maps to backend: Processing
  Shipped = 'Shipped', // Maps to backend: Shipped
  Cancelled = 'Cancelled', // Maps to backend: Cancelled
  OnHold = 'On_Hold', // Maps to backend: On Hold
  InTransit = 'In_Transit', // Maps to backend: In Transit
  In_Transit = 'In Transit', // Maps to backend: In Transit
  OutForDelivery = 'Out_for_delivery', // Maps to backend: Out for delivery
  Out_For_Delivery = 'Out for delivery', // Maps to backend: Out for delivery
  FailedAttempt = 'Failed_attempt', // Maps to backend: Failed attempt
  Failed_Attempt = 'Failed attempt', // This status comes from backed as Failed attempt and Failed_attempt needs to be send with filters to the backend
  Delivered = 'Delivered', // Maps to backend: Delivered
  AvailableForPickup = 'Available_for_Pickup', // Maps to backend: Available for Pickup
  Available_For_Pickup = 'Available for Pickup', // Maps to backend: Available for Pickup
  Expired = 'Expired', // Maps to backend: Expired
  Pending = 'Pending', // Maps to backend: Pending
  FailedDelivery = 'Failed_Delivery', // Maps to backend: Failed Delivery
  Failed_Delivery = 'Failed Delivery', // Maps to backend: Failed Delivery

  On_Hold = 'On Hold', // Why is this duplicated?

  // New statuses from the design
  UnderMedicalReview = 'Under_medical_review', // New: Under medical review
  HealthAuthorityReview = 'Health_authority_review', // New: Health authority review
  MedicalReviewDeferred = 'Medical_review_deferred', // New: Medical review deferred
  MedicalReviewDeclined = 'Medical_review_declined', // New: Medical review declined
  HealthAuthorityDeclined = 'Health_authority_declined' // New: Health authority declined
}
