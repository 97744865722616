import React from 'react'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import {
  StyledFilterWrapper,
  StyledFilterButtonGroup,
  StyledFilterButton,
  StyledTooltipFilterWrapper
} from './OrderFilter.styles'
import { ClinTheme } from '../../../../ClinTheme'
import {
  NewFeatureElements,
  PageNames
} from '../../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { useNewFeaturesList } from '../../../../hooks/useNewFeaturesList/useNewFeaturesList'

export type FilterOption = 'myOrders' | 'currentOrders' | 'previousOrders'

interface OrderFiltersProps {
  selectedFilter: FilterOption[]
  isMobile: boolean
  onFilterChange: (filter: FilterOption[]) => void
  handleCloseFeatureHighlight?: (feature: NewFeatureElements) => void
}

const filterOptions: { id: FilterOption; label: string }[] = [
  { id: 'currentOrders', label: 'Current orders' },
  { id: 'previousOrders', label: 'Previous orders' },
  { id: 'myOrders', label: 'My orders' }
]

const OrderFilters: React.FC<OrderFiltersProps> = ({
  selectedFilter,
  isMobile,
  onFilterChange,
  handleCloseFeatureHighlight
}) => {
  const handleFilterChange = (filter: FilterOption) => {
    let newFilters = [...selectedFilter]

    if (newFilters.includes(filter)) {
      newFilters = newFilters.filter((f) => f !== filter)
    } else {
      newFilters.push(filter)
    }

    onFilterChange(newFilters)
  }

  const { newFeaturesList, displayNewFeature, displayNewFeatureGlowEffect } =
    useNewFeaturesList(PageNames.Orders)

  return (
    <StyledFilterWrapper>
      {newFeaturesList &&
        displayNewFeature &&
        displayNewFeature(NewFeatureElements.OrdersFilter) && (
          <StyledTooltipFilterWrapper>
            <ClinNewFeatureTooltipContainer
              feature={newFeaturesList[NewFeatureElements.OrdersFilter]}
              openTooltipInitially={
                !newFeaturesList[NewFeatureElements.OrdersFilter]?.isCancelled
              }
              disableDismiss={true}
              total={3}
              current={3}
            ></ClinNewFeatureTooltipContainer>
          </StyledTooltipFilterWrapper>
        )}
      {!isMobile && (
        <ClinText
          color={ClinTheme.colors.black}
          variant={TypographyVariant.H5}
          fontWeight={ClinTheme.fontWeights.bold}
        >
          Filter by
        </ClinText>
      )}
      <StyledFilterButtonGroup>
        {filterOptions.map((filter) => {
          return (
            <StyledFilterButton
              isActive={selectedFilter.includes(filter.id)}
              isFeatureShow={
                newFeaturesList &&
                !!newFeaturesList[NewFeatureElements.OrdersFilter]
              }
              onClick={() => {
                if (
                  newFeaturesList &&
                  newFeaturesList[NewFeatureElements.OrdersFilter]
                ) {
                  handleCloseFeatureHighlight &&
                    handleCloseFeatureHighlight(NewFeatureElements.OrdersFilter)
                }
                handleFilterChange(filter.id)
              }}
              style={{
                height: '32px',
                paddingTop: '6px',
                zIndex: displayNewFeature(NewFeatureElements.OrdersFilter)
                  ? 3
                  : 0,
                boxShadow:
                  newFeaturesList &&
                  displayNewFeatureGlowEffect &&
                  (displayNewFeatureGlowEffect?.(
                    NewFeatureElements.OrdersFilter
                  ) ||
                    displayNewFeatureGlowEffect?.(
                      NewFeatureElements.OrdersFilter
                    ))
                    ? '0 0 14px #fff'
                    : ''
              }}
            >
              {filter.label}
            </StyledFilterButton>
          )
        })}
      </StyledFilterButtonGroup>
    </StyledFilterWrapper>
  )
}
export default OrderFilters
