import {
  CatalogItemDto,
  ExpectedDeliveryDatesDto
} from '../../types/swaggerTypes'
import { ProductItemAvailabilityStatus } from '../../constants'

export enum DrugDivisionType {
  GA = 'GA', // General Access - Clinigen sells on behalf of someone else
  CM = 'CM', // Commercial Meds - Clinigen produces themselves
  MA = 'MA', // Managed Access
  CTL = 'CTL',
  CTS = 'CTS',
  SP = 'SP',
  UNKNOWN = 'UNKNOWN'
}

export enum UIVariationState {
  // Available to order, with an accurate stock level shown. This can be thought of as a "happy path" for a full
  // basket journey.
  AvailableAccurateStockLevel = 'AvailableAccurateStockLevel', // 16
  // The product is available with good stock, but the stock level is not shown. This is a state which applies solely to SP and CM products.
  AvailableGoodStock = 'AvailableGoodStock', // 9
  // The SKU is globally unavailable.
  GloballyUnavailable = 'GloballyUnavailable', // 1, 2, 3, 4, 5
  // The product is available to buy through a regional distributor.
  AvailableDistributor = 'AvailableDistributor', // 8, 13
  // GA or CM products can be available on request.
  AvailableOnRequestOnRequest = 'AvailableOnRequestOnRequest', // 7, 14, 15 goes to existing sourcing request page
  // Products where a user can register their interest.
  AvailableOnRequestRegisterInterest = 'AvailableOnRequestRegisterInterest', // 11, 10 Country specific - goes to Service Cloud ticket, (previously included 6)
  // There is a supply problem for the product shown. This is a state that applies to GA products.
  SupplyDisruptionRegisterInterest = 'SupplyDisruptionRegisterInterest', // 12 Global - goes to Service Cloud ticket
  // Restricted or TSC expire that aren't available but can be requested.
  AvailableOnRequestSourcingRequest = 'AvailableOnRequestSourcingRequest', // 6 Global goes to existing sourcing request page
  Hidden = 'Hidden', //17 Added to hide sage products from non aus users
  // Not defined at all
  Undefined = 'Undefined'
}

/**
 * Look up table for portfolio endpoints
 */
export const getUiVariationStateEndpointRecord: Record<
  UIVariationState,
  number[]
> = {
  [UIVariationState.AvailableAccurateStockLevel]: [16],
  [UIVariationState.AvailableGoodStock]: [9],
  [UIVariationState.GloballyUnavailable]: [1, 2, 3, 4, 5],
  [UIVariationState.SupplyDisruptionRegisterInterest]: [12],
  [UIVariationState.AvailableDistributor]: [8, 13],
  [UIVariationState.AvailableOnRequestOnRequest]: [7, 14, 15],
  [UIVariationState.AvailableOnRequestRegisterInterest]: [11, 10],
  [UIVariationState.AvailableOnRequestSourcingRequest]: [6],
  [UIVariationState.Hidden]: [17],
  [UIVariationState.Undefined]: [-1]
}

export enum UILocation {
  SKU,
  Basket,
  Checkout
}

export enum AvailableWarehouse {
  T01 = 'T01', // Yusen (Belgium) T01
  C02 = 'C02', // Byfleet C02
  C05 = 'C05', // Follingsby C05
  C06 = 'C06', // Lismore warehouse
  T07 = 'T07', // Sidney warehouse
  Other = 'Other'
}

/**
 * Collection of methods to calculate various aspects of the journeys
 */
export interface IAugmentedCatalogItemDto extends CatalogItemDto {
  /** UI Variant state for various uses in UI */
  uiVariantState?: UIVariationState
  /** The endpoint number for debugging for this product */
  endpoint?: number
  /** Text to show for availability */
  availableText?: string
  /** Text to show the expected delivery date */
  expectedDate?: string
  /** Array to show the expected delivery date */
  expectedDeliveryDates?: ExpectedDeliveryDatesDto[] | undefined
  /** Text to show the expected cut off time */
  cutOffTime?: string
  /** Availability status (Decoupled from text value) */
  availableStatus?: ProductItemAvailabilityStatus
  /** Colour of text to show for availability */
  availableColour?: string
  /** Text to show for dispatch times etc */
  dispatchText?: string
  /** The product cannot be added to basket */
  cannotOrder?: boolean
  /** The product can be requested only */
  canRegisterInterest?: boolean
  /** The product can be requested if it's exclusively GA */
  canAccessSourcingRequestForm?: boolean
  /** Show distributor address and contact info */
  showDistributorInfo?: boolean
  /** Distributor name */
  distributorName?: string
  /** The amount of reservable stock (Available or Accurate Stock only) **/
  reservableStock?: number
}
