import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import React from 'react'
import { transparentize } from 'polished'
import { IClinTableRowStyles, IClinTableStyleProps } from './ClinTable'

export const StyledTableWrapper = styled.div<IClinTableStyleProps>(
  {
    display: 'block',
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch'
  },
  ({ height }: IClinTableStyleProps) => ({
    height
  })
)

interface IStyledTableProps {
  borderSpacing?: string
  borderColapseSeparate?: boolean
  padding?: string
}

export const StyledTable = styled.table<IStyledTableProps>(
  {
    width: '100%'
  },
  ({ borderColapseSeparate, borderSpacing }: IStyledTableProps) => ({
    borderCollapse: borderColapseSeparate ? 'separate' : 'collapse',
    borderSpacing: borderSpacing ?? 'unset'
  }),
  ({ padding }: IStyledTableProps) => ({
    padding: padding ?? '0'
  })
)

interface IStyledTableHeader {}

export const StyledTableHeader = styled.thead<IStyledTableHeader>({})

export const StyledTableRow = styled.tr<IClinTableRowStyles>(
  {
    color: ClinTheme.colors.black,
    transition: 'background-color 0.3s ease-out',
    borderBottom: `solid 1px ${ClinTheme.colors.grey}`
  },
  ({ disabled }: IClinTableRowStyles) => ({
    cursor: disabled ? 'initial' : 'pointer',
    [`${StyledTableBodyCell}`]: {
      color: disabled ? ClinTheme.colors.grey : 'inherit'
    },
    [`:hover`]: {
      backgroundColor: !disabled ? ClinTheme.colors.lightGrey : 'initial'
    },
    [`:focus`]: {
      outline: 'none',
      backgroundColor: !disabled
        ? transparentize(0.85, ClinTheme.colors.primaryLightest)
        : 'initial'
    }
  }),
  ({ background, disabled }: IClinTableRowStyles) => ({
    backgroundColor: background ?? 'initial',
    [`:hover`]: {
      backgroundColor: background,
      boxShadow: disabled
        ? 'unset'
        : background
        ? '0px 0px 4px 0px rgba(80, 30, 206, 0.50)'
        : 'unset'
    }
  })
)

interface IStyledBodyProps {
  customBoxShadow?: string
}

export const StyledTableBody = styled.tbody<IStyledBodyProps>(
  {
    [`${StyledTableRow}:last-of-type`]: {
      borderBottom: 'none'
    }
  },
  ({ customBoxShadow }) => ({
    boxShadow: customBoxShadow ?? 'inherit'
  })
)

export interface IStyledTableBodyCellProps
  extends React.TableHTMLAttributes<HTMLTableCellElement> {
  verticalAlign?: 'top' | 'bottom' | 'middle'
  textAlign?: 'left' | 'center' | 'right'
  paddingTop?: number
}
export const StyledTableBodyCell = styled.td<IStyledTableBodyCellProps>(
  {
    padding: `${ClinTheme.space[5]}px ${ClinTheme.space[2]}px`
  },
  ({ verticalAlign, textAlign, paddingTop }) => ({
    verticalAlign,
    textAlign,
    paddingTop
  })
)

export interface IStyledTableHeaderCellProps
  extends React.TableHTMLAttributes<HTMLTableHeaderCellElement> {
  width?: string | number
  textAlign?: 'left' | 'center' | 'right'
  background?: string
  color?: string
}
export const StyledTableHeaderCell = styled.th<IStyledTableHeaderCellProps>(
  {
    backgroundColor: ClinTheme.colors.white,
    verticalAlign: 'middle',
    textAlign: 'left',
    padding: `${ClinTheme.space[1]}px ${ClinTheme.space[2]}px`
  },
  ({ width = 'auto', textAlign }: IStyledTableHeaderCellProps) => ({
    width,
    textAlign
  })
)

export const StyledPatientTableHeaderCell =
  styled.th<IStyledTableHeaderCellProps>(
    {
      fontWeight: ClinTheme.fontWeights.medium,
      lineHeight: ClinTheme.lineHeights.largeParagraph,
      fontSize: '12px',
      padding: '16px 16px 0px 16px',
      whiteSpace: 'nowrap',
      color: ClinTheme.colors.darkGrey,
      verticalAlign: 'middle',
      textAlign: 'left'
    },
    ({ width = 'auto', textAlign }: IStyledTableHeaderCellProps) => ({
      width,
      textAlign
    }),
    ({ background, color }: IStyledTableHeaderCellProps) => ({
      backgroundColor: background ?? ClinTheme.colors.white,
      color: color ?? 'inherit'
    })
  )
