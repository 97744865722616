/***
 * Content Provider
 *
 * Provides navigation items and copy
 * eventually will be a service to interface with a CMS
 * **/

import { UserRoleWithCountry } from '../constants'
import { CountryAlphaCodes } from '../constants/countryAlpha2Codes'

export interface INavigationItem {
  path: string
  title: string
  translationKey: string
  disabled?: boolean
  cmsManaged?: boolean
  onlyAllowForCountryCodes?: string[]
}

export interface ITopTierNavigationItem {
  title: string
  path: string
  disabled: boolean
  isVisibleForMaUser: boolean
  isVisibleForAusMaUser?: boolean
  translationKey: string
  navItems?: INavigationItem[]
  hideForCountriesCode?: string[]
  hideForCountriesCodeWithRole?: UserRoleWithCountry[]
  showNewFeatureHighlight?: boolean
  fixStyle?: boolean
  displayInitialSentence?: boolean
}

export const navigationItems: ITopTierNavigationItem[] = [
  {
    title: 'Home',
    path: '/home',
    translationKey: 'navigation:home',
    isVisibleForMaUser: false,
    isVisibleForAusMaUser: true,
    disabled: false
  },

  {
    title: 'My Dashboard',
    path: '/orders',
    isVisibleForMaUser: true,
    translationKey: 'navigation:my_dashboard',
    hideForCountriesCode: [CountryAlphaCodes.Australia],
    disabled: false,
    showNewFeatureHighlight: true
  },
  {
    title: 'Products',
    path: '/products/catalogue',
    translationKey: 'navigation:products',
    isVisibleForMaUser: false,
    isVisibleForAusMaUser: true,
    disabled: false
  },
  {
    title: 'Access Programs',
    path: '/programs/access-programs',
    isVisibleForMaUser: true,
    translationKey: 'navigation:access_programs',
    hideForCountriesCodeWithRole: [
      UserRoleWithCountry.AusBase,
      UserRoleWithCountry.AusSuper
    ],
    disabled: false
  },
  {
    title: 'Patients',
    path: '/patients',
    isVisibleForMaUser: true,
    translationKey: 'navigation:patients',
    hideForCountriesCodeWithRole: [
      UserRoleWithCountry.AusBase,
      UserRoleWithCountry.AusSuper
    ],
    disabled: false,
    showNewFeatureHighlight: true
  },

  {
    title: 'My physicians',
    path: '/programs/my-physicians',
    isVisibleForMaUser: true,
    translationKey: 'navigation:my_physicians',
    hideForCountriesCodeWithRole: [
      UserRoleWithCountry.AusBase,
      UserRoleWithCountry.AusSuper
    ],
    disabled: false,
    fixStyle: true
  },

  {
    title: 'Shortages',
    path: '/shortages',
    disabled: true,
    navItems: [
      {
        path: '/shortages/uk-shortages',
        title: 'UK drug shortages',
        disabled: false,
        cmsManaged: true,
        translationKey: 'navigation:uk_shortages',
        onlyAllowForCountryCodes: ['GB']
      },
      {
        path: '/shortages/aus-shortages',
        title: 'ARTG drug shortages',
        disabled: false,
        cmsManaged: true,
        translationKey: 'navigation:aus_shortages',
        onlyAllowForCountryCodes: [CountryAlphaCodes.Australia]
      },
      {
        path: '/shortages/report',
        title: 'Report a shortage',
        disabled: false,
        translationKey: 'navigation:report_a_shortage'
      }
    ],
    isVisibleForMaUser: false,
    isVisibleForAusMaUser: true,
    translationKey: 'navigation:shortages',
    fixStyle: true
  },
  {
    title: 'Support',
    path: '/support',
    disabled: true,
    navItems: [
      {
        path: '/support',
        title: 'How To Guides',
        disabled: false,
        cmsManaged: true,
        translationKey: 'navigation:account_how_to_guides'
      },
      {
        path: '/about',
        title: 'About Clinigen Direct',
        disabled: false,
        cmsManaged: true,
        translationKey: 'navigation:about_clinigen'
      },
      {
        path: '/about/contact-us',
        title: 'Contact us',
        disabled: false,
        cmsManaged: true,
        translationKey: 'navigation:contact_us'
      }
    ],
    isVisibleForMaUser: true,
    translationKey: 'navigation:account_support',
    fixStyle: true
  },
  {
    title: 'Invite a collegue',
    path: '/new-user',
    translationKey: 'navigation:invite_colleague',
    isVisibleForMaUser: true,
    isVisibleForAusMaUser: true,
    hideForCountriesCodeWithRole: [
      UserRoleWithCountry.AusBase,
      UserRoleWithCountry.AusBaseMa,
      UserRoleWithCountry.Base,
      UserRoleWithCountry.BaseMa
    ],
    disabled: false,
    fixStyle: true,
    displayInitialSentence: true //according to clos-6774 comment
  },
  {
    title: 'Report an adverse event',
    path: '/products/adverse-event',
    translationKey: 'navigation:report_adverse_event',
    isVisibleForMaUser: true,
    isVisibleForAusMaUser: true,
    disabled: false,
    fixStyle: true
  }
]
