import React, { FunctionComponent, useEffect, useState } from 'react'
import { AppLoader } from '../../features/AppLoader'
import { useAppContext } from '../../context/app'
import {
  ActionType,
  getBasketAsync,
  useBasketDispatch
} from '../../context/basket'
import {
  createGetUserAuthEvent,
  createUserRoleEvent
} from '../../events/AuthEvent'
import {
  cancelGetUserInstitutes,
  getUserInstitutes
} from '../../services/ApiService'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  createGetInstituteEvent,
  createGetInstitutesEvent,
  createSwitchInstituteEvent
} from '../../events/InstituteEvent'
import {
  UserRole,
  UserRoleRecord,
  defaultBasketDetails,
  defaultBasketMADetails,
  isAusGaUser
} from '../../constants'
import {
  clearLocallyStoredInstitute,
  getLocallyStoredInstitute
} from '../../services/UserInstituteService'
import {
  createSetFirstAccessEvent,
  createShowInstituteModalEvent
} from '../../events/InstituteModalEvent'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import authService from '../../services/AuthService'
import { noop } from '../../utils/noop'
import { useIDSRoleCheck } from '../../context/useIDSRoleCheck'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { createUpdateSupportContactAddressEvent } from '../../events/SupportContact'
import { AnalyticsEvent } from '../../services/Analytics'
import { StyledDebugContainer } from './BootstrapSplash.styles'
import { ErrorSplash } from './ErrorSplash'
import { ClinSpinner } from '../../components/ClinSpinner'
import { createBootstrappedEvent } from '../../events/BootstrapEvent'
import { useTranslation } from 'react-i18next'
import { i18nRef } from '../../i18n/i18nRef'
import { defaultLanguageEnglish, defaultLanguages } from '../../i18n/config'
import { useOnboardingCheck } from '../../context/useOnboardingCheck'
import { getNewFeaturesAsync } from '../../context/newFeatures/NewFeaturesContext.async'
import { useNewFeaturesDispatch } from '../../context/newFeatures'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { getUserPhone } from '../../utils/getUserPhone'
import { localStorageRecipientName } from '../../types/localStorageConstants'
import { QueryParam } from './BootstrapSplashConstants'
import { getPathValueFromUrl } from '../../utils/getPathValue'
import { AnnounceEventType } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'

interface ISplashProps {
  /** Show debug information */
  showDebug?: boolean
}

const Tick = () => (
  <span
    role="img"
    aria-label="tick"
    style={{ fontSize: ClinTheme.fontSizes[5] }}
  >
    ☑
  </span>
)

export const BootstrapSplashContainer: FunctionComponent<ISplashProps> = ({
  showDebug = false,
  children
}) => {
  const { t, i18n } = useTranslation()
  const [loadingText, setLoadingText] = useState<string>('...')
  const {
    dispatch,
    user,
    userDetails,
    institute,
    portfolioCountryCode,
    institutes,
    supportContact,
    isBootstrapped,
    bootstrapError,
    userRole
  } = useAppContext()

  useOnboardingCheck(userDetails)
  const isUserWithRolesLoaded = useIDSRoleCheck(user)
  const [preferredLanguageId, setPreferredLanguageId] = useState<string>(
    defaultLanguageEnglish
  )

  const handleFetchInstitutesError = useErrorMessage(
    t('app_loader:error_fetching_institutes'),
    true
  )
  const handleFetchInstituteError = useErrorMessage(
    t('app_loader:error_fetching_institute'),
    true
  )

  useEffect(() => {
    setLoadingText(t('app_loader:loading_user'))
    dispatch(createGetUserAuthEvent())
  }, [dispatch])

  const [userInstituteId, setUserInstituteId] = useState<
    string | null | undefined
  >(undefined)

  useEffect(() => {
    if (isUserWithRolesLoaded && userDetails) {
      getUserInstitutes()
        .then((response) => {
          dispatch(createGetInstitutesEvent(response.data))
          return response
        })
        .then((response) => {
          getLocallyStoredInstitute().then((inst) => {
            const foundInstitute = response.data.find(
              (institute) =>
                institute.instituteId.toString() ===
                inst?.instituteId.toString()
            )
            if (localStorage.getItem(QueryParam.ReturnUrl)) {
              const storedJsonString = localStorage.getItem(
                QueryParam.ReturnUrl
              )
              if (storedJsonString !== null) {
                let organizationId = JSON.parse(storedJsonString).organization
                setUserInstituteId(organizationId)
              }
            } else if (foundInstitute) {
              setUserInstituteId(foundInstitute.instituteId.toString())
            } else {
              setUserInstituteId(null)
            }
          })
        })
        .catch((error) => {
          handleFetchInstitutesError(error)
        })

      /* If the current user has a preferred language that matches the one of the default languages
      then set this on load otherwise fall back to 'en' */
      const preferredLanguage = defaultLanguages.find(
        (l) =>
          l.id.toLowerCase() ===
          userDetails?.contactCard.languagePreference.toLowerCase()
      )
      preferredLanguage && setPreferredLanguageId(preferredLanguage.id)
    }
  }, [dispatch, handleFetchInstitutesError, isUserWithRolesLoaded, userDetails])

  useEffect(() => {
    /* Once a preferred language is available update i18n */
    if (preferredLanguageId) {
      i18n.changeLanguage(preferredLanguageId).then(() => noop())
    }
  }, [i18n, preferredLanguageId])

  useEffect(() => {
    //first check if we have form in url and than check other things for redirect to users form
    const formId = getPathValueFromUrl(
      window.location.pathname,
      QueryParam.Form
    )

    if (formId) {
      const organizationId = getPathValueFromUrl(
        window.location.pathname,
        QueryParam.Organization
      )

      const accessProgramId = getPathValueFromUrl(
        window.location.pathname,
        QueryParam.AccessProgram
      )

      const physicianId = getPathValueFromUrl(
        window.location.pathname,
        QueryParam.PhysicianId
      )

      if (organizationId && accessProgramId && physicianId) {
        setUserInstituteId(organizationId)
        localStorage.setItem(
          QueryParam.ReturnUrl,
          JSON.stringify({ organization: organizationId, form: formId })
        )
        window.location.href = `${window.location.protocol}//${window.location.host}/programs/access-programs/${accessProgramId}/patient-access-form/${physicianId}/form/${formId}`
      }
    }
  }, [])

  useEffect(() => {
    institutes &&
      isUserWithRolesLoaded &&
      getLocallyStoredInstitute()
        .then((response) => {
          let instituteId = response?.instituteId.toString()
          let currentInstitute

          const resupplyPathParts = window.location.pathname.split('/')
          const resupplyInstOPA = resupplyPathParts[7]

          // Set it by default if only one
          if (institutes.length === 1) {
            currentInstitute = institutes[0]
            dispatch(createSwitchInstituteEvent(currentInstitute.instituteId))
            dispatch(createSetFirstAccessEvent(false))
            return
          } else if (userInstituteId) {
            if (resupplyInstOPA != undefined) {
              dispatch(createGetInstituteEvent(resupplyInstOPA))
              instituteId = resupplyInstOPA
            } else {
              dispatch(createGetInstituteEvent(userInstituteId))
            }
          } else {
            dispatch(createShowInstituteModalEvent())
          }

          // Calc current institute
          currentInstitute = institutes.find(
            (item) => item.instituteId.toString() === instituteId
          )

          // If there is no institute then show modal
          if (
            response === null &&
            !localStorage.getItem(QueryParam.ReturnUrl)
          ) {
            const pathParts = window.location.pathname.split('/')
            const instituteOPA = pathParts[7]

            const currentInstitute = instituteOPA
              ? { instituteId: instituteOPA }
              : institutes[0]

            currentInstitute &&
              dispatch(
                createSwitchInstituteEvent(currentInstitute.instituteId, false)
              )
            dispatch(createSetFirstAccessEvent(true))
            dispatch(createShowInstituteModalEvent())
          }
        })
        .catch((error) => {
          handleFetchInstituteError(error)
        })
    return () => {
      cancelGetUserInstitutes()
    }
  }, [
    dispatch,
    handleFetchInstituteError,
    institutes,
    isUserWithRolesLoaded,
    userInstituteId
  ])

  // Wait for institute and user role to be available and save in shared app context
  useEffect(() => {
    if (institute.data && userDetails && isUserWithRolesLoaded) {
      const matchingRelationship =
        userDetails.contactCard.clientRelationships.find(
          (item) => item.instituteId === institute.data?.instituteId
        )

      if (matchingRelationship) {
        const role = matchingRelationship.roleType as UserRole
        dispatch(createUserRoleEvent(role))
      } else {
        console.error(
          `No matching institute relationship found for institute ID: ${institute.data.instituteId}`
        )
        dispatch({
          type: AnnounceEventType.SHOW,
          mode: AnnounceMode.Error,
          description: `No matching institute found. Please contact support.`
        })
      }
    }
  }, [dispatch, isUserWithRolesLoaded, institute, userDetails])

  // Watch the portfolioCountryCode and if it changes update the support details
  useEffect(() => {
    if (portfolioCountryCode && isUserWithRolesLoaded && userDetails) {
      dispatch(createUpdateSupportContactAddressEvent(portfolioCountryCode))
    }
  }, [portfolioCountryCode, dispatch, isUserWithRolesLoaded, userDetails])

  // Update loader text
  useEffect(() => {
    if (user && userDetails) {
      setLoadingText(t('app_loader:user_details_loaded'))
    }
    if (supportContact && supportContact.countryName) {
      setLoadingText(t('app_loader:support_info_loaded'))
    }
    if (institutes) {
      setLoadingText(t('app_loader:institutes_loaded'))
    }
    if (institute && institute.data) {
      setLoadingText(t('app_loader:current_institute_loaded'))
    }
  }, [
    user,
    userDetails,
    supportContact,
    institutes,
    institute,
    bootstrapError,
    isUserWithRolesLoaded,
    t
  ])

  // Get the user's current basket once institute is loaded
  const basketDispatch = useBasketDispatch()

  useEffect(() => {
    if (institute?.data?.instituteId && isUserWithRolesLoaded) {
      getBasketAsync(basketDispatch).then(() =>
        setLoadingText(t('app_loader:basket_loaded'))
      )
    }
  }, [basketDispatch, isUserWithRolesLoaded, institute, t])

  useEffect(() => {
    const isMaUser = !!(
      userRole && UserRoleRecord[userRole as UserRole].isMaUser
    )
    const defaultRoleBasketDetails = isMaUser
      ? defaultBasketMADetails
      : defaultBasketDetails
    basketDispatch({
      type: ActionType.UpdateBasketDetails,
      basketDetails: {
        ...defaultRoleBasketDetails,
        deliverToContact: localStorage.getItem(localStorageRecipientName) ?? '',
        recipientEmail: userDetails?.contactCard.email,
        recipientPhoneNumber: getUserPhone(userDetails?.contactCard)
      }
    })
  }, [basketDispatch, userRole, userDetails])

  // If not bootstrapped already, check if all loaded!
  useEffect(() => {
    if (
      !isBootstrapped &&
      user &&
      userDetails &&
      institutes &&
      institute.data &&
      supportContact &&
      bootstrapError === undefined &&
      isUserWithRolesLoaded &&
      i18nRef.isLoaded
    ) {
      setLoadingText(t('app_loader:ready'))
      // Update the analytics singleton with the users preferred language before submitting tracked events
      analyticsServiceSingleton.language =
        userDetails.contactCard.languagePreference
      // Update the analytics singleton with the users institute before submitting tracked events
      analyticsServiceSingleton.instituteId = String(
        institute.data?.instituteId
      )
      // Update the analytics singleton with the users UI variety
      analyticsServiceSingleton.platformVersion = String(
        portfolioCountryCode === CountryAlphaCodes.Australia
          ? isAusGaUser(portfolioCountryCode, userRole)
            ? 'Aus GA'
            : 'Aus MA'
          : 'global'
      )
      // Dispatch analytics event to show we have logged in with userId and instituteId
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.UserLoggedIn, {
        instituteId: institute.data?.instituteId,
        userId: userDetails?.contactCard.contactId
      })
      // Tell the app we are ready to go
      dispatch(createBootstrappedEvent(true))
    }
  }, [
    dispatch,
    user,
    userDetails,
    supportContact,
    institutes,
    institute,
    bootstrapError,
    isUserWithRolesLoaded,
    isBootstrapped,
    t
  ])

  const newFeaturesDispatch = useNewFeaturesDispatch()

  useEffect(() => {
    if (user) {
      getNewFeaturesAsync(newFeaturesDispatch)
    }
  }, [newFeaturesDispatch, user])

  /**
   * Button handlers
   */
  const handleTryAgain = () => {
    // Clear cookie for institute
    clearLocallyStoredInstitute()
    window.location.href = '/'
  }

  const handleLogOut = () => {
    authService.logout().then(() => noop())
  }

  const getContactEmail = (
    portfolioCountryCode: string,
    userRole: UserRole
  ) => {
    if (
      portfolioCountryCode === CountryAlphaCodes.Australia &&
      isAusGaUser(portfolioCountryCode, userRole)
    ) {
      return 'customerservice@linkhealthcare.com.au'
    }
    return 'medicineaccess@clinigengroup.com'
  }

  return (
    <>
      {isBootstrapped ? (
        <>{children}</>
      ) : !bootstrapError ? (
        <>
          <AppLoader loadingText={loadingText} />
          {showDebug ? (
            <StyledDebugContainer>
              <ClinText>User: {user ? <Tick /> : <ClinSpinner />}</ClinText>

              <ClinText>
                {`${t('app_loader:institutes')} `}
                {institutes ? <Tick /> : <ClinSpinner />}
              </ClinText>

              <ClinText>
                {`${t('app_loader:user_institute')} `}
                {institute.data ? <Tick /> : <ClinSpinner />}
              </ClinText>

              <ClinText>
                {`${t('app_loader:support_contact')} `}
                {supportContact && supportContact.countryName ? (
                  <Tick />
                ) : (
                  <ClinSpinner />
                )}
              </ClinText>
            </StyledDebugContainer>
          ) : null}
        </>
      ) : (
        <ErrorSplash
          bootstrapError={bootstrapError}
          supportContact={{
            csEmailAddress: getContactEmail(
              portfolioCountryCode,
              userRole as UserRole
            ),
            csPhoneNumber: '+44 (0) 1283 494340',
            rawPhoneNumber: '+441283494340'
          }}
          handleTryAgain={handleTryAgain}
          handleLogOut={handleLogOut}
        />
      )}
    </>
  )
}
