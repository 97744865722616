import DHLLogo from '../assets/media/delivery-logos/dhl-logo.svg'
import CryoLogo from '../assets/media/delivery-logos/cryopdp-logo.svg'
import MarkenLogo from '../assets/media/delivery-logos/marken-logo.svg'
import QSLogo from '../assets/media/delivery-logos/qs-logo.svg'
import WorldLogo from '../assets/media/delivery-logos/world-courier.svg'
import FedexLogo from '../assets/media/delivery-logos/fedex-express-6.svg'
import DPDLogo from '../assets/media/delivery-logos/DPD_logo.svg'
import UnitedParcelLogo from '../assets/media/delivery-logos/United_Parcel_Service_logo_2014.svg'
import { UpdateBasketDetailsDto } from '../types/swaggerTypes'

export enum OrderDelivery {
  Regular = 'Regular',
  Custom = 'Custom'
}

export enum InvoiceFlags {
  Y = 'Y',
  N = 'N'
}

export enum AftershipSupport {
  Aftership = 'Aftership',
  Partial = 'Partial',
  NoSupport = 'Not supported'
}

export enum OrderCancellationCodes {
  XXCLGREATERQTY = 'XXCLGREATERQTY',
  XXCLORDERR = 'XXCLORDERR',
  XXCLLEADTIME = 'XXCLLEADTIME',
  XXCLLOWPRICE = 'XXCLLOWPRICE',
  XXCLMVPOTH = 'XXCLMVPOTH'
}

export enum OrderColumnNames {
  ToggleExpand = 'Toggle Expand',
  OrderNumber = 'orderNumber',
  PurchaseOrderNumber = 'poNumber',
  Medications = 'Medications',
  DateSubmitted = 'orderedDate',
  TotalCost = ' Total cost',
  Status = 'orderStatus'
}

export const regularShippingOptions = [
  'DHL-Road-Express EOD',
  'DPD-Road-Express EOD',
  'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
  'FEDEX_PRIORITY',
  'DHL-Air-Express pre 12',
  'DHL-Air-Express EOD',
  'FDX-Air-Next Day International',
  'FEDEX_INTERNATIONAL_PRIORITY',
  'PSPEED-Road-Next Day',
  'PSPEED-Road-Pre-12',
  'PSPEED-Road-Pre-10',
  'PSPEED-Road-Pre-9'
]

export const otherShippingOptions = [
  'DHL-Road-Express EOD',
  'DPD-Road-Express EOD',
  'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
  'INTERNATIONAL_PRIORITY_FREIGHT',
  'FEDEX_PRIORITY',
  'PRIORITY_OVERNIGHT',
  'DEF-Default-Please Select Carrier & Service',
  'Other-Parcel-Standard',
  'XXCLCONS-Default-Standard',
  'Supplier-Default-Standard',
  'DHL-Air-Express pre 12',
  'DHL-Air-Express EOD',
  'FDX-Air-Next Day International',
  'FEDEX_INTERNATIONAL_PRIORITY',
  'KUN-Air-Standard',
  'KUN-Road-Standard',
  'KUN-Ocean-Standard',
  'MARK-Parcel-Standard',
  'PDP-Air-Standard',
  'QSTAT-Parcel-Standard',
  'TEK-Parcel-Standard',
  'UPS-Air-Standard',
  'UPS-Road-Standard',
  'UPS-Ocean-Standard',
  'XM-LTL-Same Day UK',
  'UPSSTD-Parcel-Standard',
  'WCR-Air-Air',
  'PSPEED-Road-Next Day',
  'PSPEED-Road-Pre-12',
  'PSPEED-Road-Pre-10',
  'PSPEED-Road-Pre-9'
]

//Type determines if carrier is supported by Aftership
interface ICarrierLookup {
  Description: string
  Meaning: string
  LogoSrc: string
  Type: string
}

export const carrierLookup: ICarrierLookup[] = [
  {
    Description: 'DHL',
    Meaning: 'DHL International (UK) Ltd',
    LogoSrc: DHLLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'FDX',
    Meaning: 'Fedex (UK) Ltd',
    LogoSrc: FedexLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'KN',
    Meaning: 'Kuehne + Nagel International AG',
    LogoSrc: '',
    Type: AftershipSupport.NoSupport
  },
  {
    Description: 'MARK',
    Meaning: 'Marken Limited',
    LogoSrc: MarkenLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'PDP',
    Meaning: 'PDP Couriers Services Ltd.',
    LogoSrc: CryoLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'PSPEED',
    Meaning: 'Polar Speed Ltd',
    LogoSrc: '',
    Type: AftershipSupport.Partial
  },
  {
    Description: 'QSTAT',
    Meaning: 'QuickSTAT',
    LogoSrc: QSLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'TEK',
    Meaning: 'T E K Freight',
    LogoSrc: '',
    Type: AftershipSupport.NoSupport
  },
  {
    Description: 'ups-freight',
    Meaning: 'UPS Freight',
    LogoSrc: UnitedParcelLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'UPS',
    Meaning: 'UPS-Parcel-Standard',
    LogoSrc: UnitedParcelLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'worldcourier',
    Meaning: 'World Courier',
    LogoSrc: WorldLogo,
    Type: AftershipSupport.Aftership
  },
  {
    Description: 'XM',
    Meaning: 'Xpress Messenger - Couriers LLP',
    LogoSrc: '',
    Type: AftershipSupport.NoSupport
  },
  {
    Description: 'DPD',
    Meaning: 'DPDgroup UK Ltd',
    LogoSrc: DPDLogo,
    Type: AftershipSupport.Aftership
  }
]

export const defaultBasketDetails: UpdateBasketDetailsDto = {
  shippingAddressId: '',
  currencyCode: undefined,
  poNumber: undefined,
  deliverToContact: '',
  deliveryOption: OrderDelivery.Regular,
  recipientEmail: '',
  recipientPhoneNumber: ''
}

export const defaultBasketMADetails: UpdateBasketDetailsDto = {
  shippingAddressId: '',
  currencyCode: undefined,
  poNumber: '',
  deliverToContact: '',
  deliveryOption: OrderDelivery.Custom,
  isMaOrder: true,
  recipientEmail: '',
  recipientPhoneNumber: ''
}
