import React, { FunctionComponent, useEffect, useState } from 'react'
import { IUpdateContactDto, Onboarding } from './Onboarding'
import {
  InstituteContactDto,
  MarketingPreferenceDto
} from '../../types/swaggerTypes'
import { useAppContext } from '../../context/app'
import {
  cancelUpdateContact,
  cancelUpdateMarketPreferences,
  getContactDetails,
  getMarketPreferences,
  updateContact,
  updateMarketPreferences
} from '../../services/ApiService'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinTheme } from '../../ClinTheme'
import { Col, Container, Row } from 'react-grid-system'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { useHistory } from 'react-router-dom'
import { LovName, isAusGaUser } from '../../constants'
import { useLov } from '../../utils/useLov'
import { noop } from 'lodash'
import authService from '../../services/AuthService'
import i18n, { defaultLanguages } from '../../i18n/config'
import {
  defaultPreferences,
  selectedAllPreferences
} from '../Preferences/Preferences.model'
import { AxiosError } from 'axios'
import { createGetUserAuthEvent } from '../../events/AuthEvent'
import { scrollToTrackingLinkKey } from '../OrderPages/OrderDetail/OrderDetail.model'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

export const OnboardingContainer: FunctionComponent = () => {
  const { userDetails, dispatch, portfolioCountryCode, userRole } =
    useAppContext()
  const history = useHistory()
  const orderNumber =
    userDetails && localStorage.getItem(userDetails?.contactCard.name)

  const [isSubmitting, setIsSubmitting] = useState<boolean>()
  const [userContact, setUserContact] = useState<
    InstituteContactDto | undefined
  >()
  const [marketingPreferenceStatus, setMarketingPreferenceStatus] =
    useState<boolean>(false)
  const [paginationAltMode, setPaginationAltMode] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [totalSteps, setTotalSteps] = useState<number>(3)
  const [newLang, setNewLang] = useState<string>(i18n.language)
  const [redirectTarget, setRedirectTarget] = useState<string>('/orders')
  const [skippedQuestion, setSkippedQuestion] = useState<boolean>(false)
  const { isLovLoading: specialismsLoading, lovValues: specialisms } = useLov(
    LovName.SPECIALISM
  )
  const { isLovLoading: jobTitlesLoading, lovValues: jobTitles } = useLov(
    LovName.JOB_TITLES
  )

  const [preferences, setPreferences] = useState<MarketingPreferenceDto[]>([])
  const { enableNewHomePage } = useCheckUnableHomePage()

  useEffect(() => {
    if (userDetails) {
      getContactDetails(`${userDetails.contactCard.contactId}`)
        .then((response) => {
          setUserContact(response.data)
        })
        .catch((error) => {
          const { title, detail, message } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching your user details. ${title ?? ''} ${
                message ?? ''
              }`
            )
          )
          console.warn(title, detail)
        })
    }
  }, [userDetails, dispatch])

  useEffect(() => {
    getMarketPreferences()
      .then((response) => {
        if (response.data.preferences) {
          setPreferences(response.data.preferences)
        }
        setMarketingPreferenceStatus(
          /* If lastUpdated is set to null then the user is new to the platform
          and is allowed to post a marketing preference to hubspot */
          response.data.lastUpdated !== null
        )
      })
      .catch((error) => {
        const { title, detail, message } = error.response.data
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error fetching your marketing preferences. ${
              title ?? ''
            } ${message ?? ''}`
          )
        )
        console.warn(title, detail)
      })
  }, [dispatch])

  const handleFormSubmission = (values: IUpdateContactDto) => {
    setIsSubmitting(true)

    //Adds marketing preference Name based on order, checks if the user skipped the question even after checking the checkboxes
    let marketingValuesPayload: MarketingPreferenceDto[]
    if (!skippedQuestion) {
      marketingValuesPayload = values.preferences.map((item, index) => {
        return {
          subscriptionName: selectedAllPreferences[index].subscriptionName,
          optedIn: item.optedIn
        }
      })
    } else {
      marketingValuesPayload = defaultPreferences
    }
    //Actual call to update preferences
    marketingValuesPayload &&
      updateMarketPreferences(marketingValuesPayload).catch(
        (error: AxiosError) => {
          if (error.response) {
            const { title, detail, message } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error updating your marketing preferences. ${
                  title ?? ''
                } ${message ?? ''}`
              )
            )
            console.warn(title, detail)
          }
        }
      )

    // Update contact details
    userDetails &&
      updateContact(`${userDetails.contactCard.contactId}`, values)
        .then((response) => {
          setIsSubmitting(false)
          dispatch(createGetUserAuthEvent())
          if (response.status >= 200 && response.status <= 299) {
            if (redirectTarget.startsWith('/order/')) {
              localStorage.setItem(scrollToTrackingLinkKey, 'True')
            }
            history.replace(redirectTarget)
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          const { title, detail, message } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error updating your user details. ${title ?? ''} ${
                message ?? ''
              }`
            )
          )
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          console.warn(title, detail)
        })
  }

  useEffect(() => {
    return () => {
      cancelUpdateContact()
      cancelUpdateMarketPreferences()
    }
  }, [])

  /**
   * Log out button clicked
   */
  const handleLogOut = () => {
    authService.logout().then(() => noop())
  }

  //Change language internally until preferences are Submitted at which point the lang will come from the user response
  useEffect(() => {
    i18n.changeLanguage(newLang)
  }, [newLang])

  const handleNextActiveStep = () => {
    setActiveStep(activeStep + 1)
  }

  // Not used in the current implementation
  const handleRedirectToOrders = () => {
    const orderPath = orderNumber ? `/order/${orderNumber}` : '/orders' //redirect to order clos-2966
    setRedirectTarget(
      isAusGaUser(portfolioCountryCode, userRole) && enableNewHomePage
        ? '/home'
        : orderPath
    )
  }

  const handleRedirectToHome = () => {
    setRedirectTarget('/home')
  }

  // Not used in the current implementation
  const handleRedirectToProducts = () => {
    const productsPath = '/products/catalogue'
    setRedirectTarget(
      isAusGaUser(portfolioCountryCode, userRole) && enableNewHomePage
        ? '/home'
        : productsPath
    )
  }

  const handleRedirectToAccDetails = () => {
    setRedirectTarget('/account/details')
  }

  useEffect(() => {
    if (
      !userDetails?.contactCard.classification ||
      userDetails?.contactCard.classification === ''
    ) {
      setActiveStep(0) //your job screen
      setTotalSteps(3)
    } else if (
      (userDetails.contactCard.classification.toLowerCase() === 'physician' ||
        userDetails.contactCard.classification.toLowerCase() ===
          'pharmacist') &&
      (!userDetails.contactCard.licenseNumber ||
        userDetails.contactCard.licenseNumber === '')
    ) {
      setActiveStep(0) //your job screen
      setTotalSteps(3)
    } else if (
      !userDetails.contactCard.specialism ||
      userDetails.contactCard.specialism === ''
    ) {
      setPaginationAltMode(true)
      setActiveStep(1)
      setTotalSteps(3) //your specialism
    }
  }, [userDetails])

  return !userContact || specialismsLoading || jobTitlesLoading ? (
    <Container>
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  ) : (
    <Onboarding
      preferences={preferences}
      showDebug={false}
      isSubmitting={isSubmitting}
      user={userContact}
      specialisms={specialisms.sort()}
      jobTitles={jobTitles.sort((a, b) => {
        if (a.lovValue! < b.lovValue!) {
          return -1
        } else return 1
      })}
      marketingPreferenceStatus={marketingPreferenceStatus}
      handleFormSubmission={handleFormSubmission}
      handleLogOut={handleLogOut}
      handleNextActiveStep={handleNextActiveStep}
      activeStep={activeStep}
      totalSteps={totalSteps}
      userDetails={userDetails}
      languageOptions={defaultLanguages}
      handleChangedLanguage={setNewLang}
      handleRedirectToAccDetails={handleRedirectToAccDetails}
      handleRedirectToOrders={handleRedirectToOrders}
      handleRedirectToProducts={handleRedirectToProducts}
      handleRedirectToHome={handleRedirectToHome}
      handleSkippedQuestion={setSkippedQuestion}
      paginationAltMode={paginationAltMode}
    />
  )
}
