import React, {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect
} from 'react'
import { ClinTheme } from '../../ClinTheme'
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system'
import {
  StyledButtonLoadingContainer,
  StyledFormContainer,
  StyledLoadingSpinner,
  StyledOnboarding
} from './Onboarding.styles'
import { ClinLogo } from '../../components/ClinLogo'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  ContactPhoneDto,
  InstituteContactDto,
  LovDto,
  MarketingPreferenceDto,
  UpdateContactDto,
  UserDto
} from '../../types/swaggerTypes'
import { ClinTextInput } from '../../components/ClinTextInput'
import { ClinSelect } from '../../components/ClinSelect'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { ClinButton } from '../../components/ClinButton'
import {
  OnboardingCommonJobs,
  OnboardingCommonSpecialisms,
  PhoneResponseType
} from '../../constants'
import { ClinSpinner } from '../../components/ClinSpinner'
import { requiredMinStringLength } from '../../utils/Forms/requiredMinStringLength'
import { useTranslation } from 'react-i18next'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../utils/Forms/getInputPlaceholder'
import { ClinChip } from '../../components/ClinChip'
import { ICustomSelectOption } from '../../features/CustomSelect/CustomSelect'
import { ClinPaginationDots } from '../../components/ClinPaginationDots'
import { OnboardingLineItem } from '../../components/OnboardingLineItem'
import { ClinMarketingPreferenceWithIcon } from '../../components/ClinMarketingPreferences'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { selectedAllPreferences } from '../Preferences/Preferences.model'

export interface IUpdateContactDto extends UpdateContactDto {
  receiveMarketing: boolean
  preferences: MarketingPreferenceDto[]
}

interface IOnboardingProps {
  /** Whether to show debug */
  showDebug?: boolean
  /** The user we are updating */
  user: InstituteContactDto
  /** A value to check if the user has accepted marketing preferences */
  marketingPreferenceStatus: boolean
  /** Whether the form submission is in progress */
  isSubmitting?: boolean
  /** An array of specialism values */
  specialisms?: LovDto[]
  /** An array of lov job title values */
  jobTitles?: LovDto[]
  /** Active step of the form */
  activeStep: number
  totalSteps: number
  languageOptions: ICustomSelectOption[]
  preferences: MarketingPreferenceDto[]
  paginationAltMode?: boolean
  userDetails?: UserDto
  /** Handle the submit button */
  handleFormSubmission: (values: IUpdateContactDto) => void
  /** Handle click on "Log out" button */
  handleLogOut?: () => void
  handleNextActiveStep: () => void
  handleChangedLanguage: (newLang: string) => void
  handleRedirectToAccDetails: () => void
  handleRedirectToHome: () => void
  handleRedirectToOrders: () => void
  handleRedirectToProducts: () => void
  handleSkippedQuestion: Dispatch<SetStateAction<boolean>>
}

export const Onboarding: FunctionComponent<IOnboardingProps> = ({
  showDebug,
  isSubmitting,
  user,
  specialisms,
  jobTitles,
  marketingPreferenceStatus,
  preferences,
  activeStep,
  totalSteps,
  userDetails,
  paginationAltMode,
  handleFormSubmission,
  handleNextActiveStep,
  handleChangedLanguage,
  handleRedirectToAccDetails,
  handleRedirectToOrders,
  handleRedirectToProducts,
  handleRedirectToHome,
  handleSkippedQuestion,
  languageOptions
}) => {
  const { t } = useTranslation()

  const genericPhone =
    user.phones &&
    user.phones.find((phone) => phone.phoneType === PhoneResponseType.GEN)

  const onboardingSchema = object().shape({
    licenseNumber: string().when('classification', {
      is: (classification: string) =>
        classification &&
        (classification.toLowerCase() === 'pharmacist' ||
          classification.toLowerCase() === 'physician'),
      then: requiredMinStringLength(50)
    }),
    specialism: requiredMinStringLength(240),
    classification: requiredMinStringLength(240).nullable()
  })

  /* Created a default object to form the same shape as the object being posted. Previously these were different so that when
 a user sets a value to it's original state the isDirty flag wouldn't work because the default object was then modified */
  const defaultPhones =
    user.phones?.length > 0
      ? user.phones?.map((phone) => ({
          phoneId: phone.phoneId,
          phoneType: phone.phoneType,
          requestType: 'UPDATE',
          countryCode: phone.countryCode,
          areaCode: phone.areaCode,
          phoneNumber: phone.phoneNumber,
          phoneExtension: phone.phoneExtension
        }))
      : [
          {
            phoneId: '',
            phoneType: 'GEN',
            requestType: 'CREATE',
            countryCode: '44',
            areaCode: '',
            phoneNumber: '',
            phoneExtension: ''
          }
        ]

  let defaultState: IUpdateContactDto = {
    licenseNumber: '',
    firstName: user.firstName ? user.firstName : '',
    lastName: user.lastName ? user.lastName : '',
    preferredName: user.preferredName
      ? user.preferredName
      : user.firstName
      ? user.firstName
      : '',
    phones: defaultPhones,
    title: user.title ? user.title : '',
    specialism: user.specialism ? user.specialism : '',
    classification: user.classification ? user.classification : '',
    receiveMarketing: marketingPreferenceStatus,
    preferences: []
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger,
    control
  } = useForm<IUpdateContactDto>({
    mode: 'all',
    defaultValues: user.licenseNumber
      ? { ...defaultState, licenseNumber: user.licenseNumber }
      : defaultState,
    resolver: yupResolver(onboardingSchema)
  })

  const formValues = watch()
  const watchClassification = watch('classification')
  const watchPhones = watch('phones')
  const watchSpecialism = watch('specialism')
  const watchLicense = watch('licenseNumber')
  const showLicenceNumber =
    watchClassification &&
    (watchClassification.toLowerCase() === 'pharmacist' ||
      watchClassification.toLowerCase() === 'physician')

  const handleChipClicked = (val: string, id: string) => {
    if (val && val === 'Site Co-ordinator')
      setValue('classification', 'SITE CO_ORDINATOR')
    else if (id === 'classification') {
      val && setValue('classification', val)
      trigger(id)
    } else if (id === 'specialism') {
      val && setValue('specialism', val)
      trigger(id)
    }
  }

  const handleSelectAll = () => {
    setValue('preferences', selectedAllPreferences)
  }

  const ifRedirectToOrders =
    userDetails &&
    userDetails.contactCard.name &&
    localStorage.getItem(userDetails.contactCard.name)

  const getSubmitButtonText = () => {
    if (ifRedirectToOrders) return t('onboarding:take_me_to_order')

    return t('onboarding:take_me_to_products')
  }

  useEffect(() => {
    watchPhones[0] &&
      (watchPhones[0].countryCode === undefined ||
        watchPhones[0].countryCode === null ||
        watchPhones[0].countryCode === '') &&
      setValue('phones.0.countryCode', '44', {
        shouldDirty: true
      })
  }, [setValue, watchPhones])

  return (
    <>
      <form
        noValidate={true}
        onSubmit={handleSubmit((values) => {
          const filterPhoneResponses = values.phones.filter(
            (item: ContactPhoneDto) => item.areaCode && item.phoneNumber
          )
          // Map return values
          const updatedUser: IUpdateContactDto = {
            ...values,
            phones: filterPhoneResponses
          }
          handleFormSubmission && handleFormSubmission(updatedUser)
        })}
      >
        {activeStep !== totalSteps && (
          <StyledOnboarding
            style={{ backgroundColor: 'rgba(0,0,0,0.4)', minHeight: '100vh' }}
          >
            <Container fluid={false}>
              <ClinSpacer height={ClinTheme.space[10]} />
              <Row align="center" justify="center">
                <StyledFormContainer
                  style={{ maxWidth: '946px', padding: '0' }}
                >
                  <Row style={{ width: '100%', padding: '0' }}>
                    <Col
                      width={'38.48%'}
                      style={{
                        padding: '54px 50px 78px 40px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: ClinTheme.colors.lightGrey
                      }}
                    >
                      <ClinText
                        as="h1"
                        variant={TypographyVariant.LargerParagraph}
                        color={ClinTheme.colors.primary}
                      >
                        {t('onboarding:title')}
                      </ClinText>

                      <ClinText
                        as="h1"
                        variant={TypographyVariant.LargeParagraph}
                        fontSize={ClinTheme.fontSizes[2]}
                      >
                        {t('onboarding:description')}
                      </ClinText>

                      <ClinSpacer />
                      <ClinLogo
                        style={{
                          marginTop: 'auto',
                          marginBottom: '22px',
                          width: '152px'
                        }}
                      />
                      <ClinText
                        as="small"
                        color={ClinTheme.colors.primary}
                        fontSize={ClinTheme.fontSizes[0]}
                      >
                        {t('onboarding:logo_description')}
                      </ClinText>
                    </Col>

                    <Col width={'61.52%'} style={{ paddingLeft: '50px' }}>
                      <ClinSpacer height={ClinTheme.space[8]} />
                      {activeStep === 0 && (
                        <>
                          <Row style={{ alignItems: 'center' }}>
                            <Col xs={12} lg={9}>
                              <ClinText
                                as="h1"
                                variant={TypographyVariant.LargerParagraph}
                                color={ClinTheme.colors.primary}
                              >
                                {t('onboarding:first_step_title')}
                              </ClinText>
                            </Col>
                            <Col>
                              <ClinPaginationDots
                                totalIndex={totalSteps}
                                activeIndex={activeStep}
                                alt={paginationAltMode}
                              ></ClinPaginationDots>
                            </Col>
                          </Row>
                          <ClinSpacer />
                          {jobTitles && (
                            <>
                              <Row>
                                <Col xs={12} lg={9}>
                                  <Controller
                                    name="classification"
                                    control={control}
                                    render={({
                                      field: { onChange, value }
                                    }) => (
                                      <ClinSelect
                                        id="classification"
                                        label={t('common:labels.job_type')}
                                        width="100%"
                                        value={value}
                                        onChange={(
                                          changeValue: ChangeEvent<HTMLSelectElement>
                                        ) =>
                                          onChange(
                                            changeValue.currentTarget.value
                                          )
                                        }
                                        hasError={
                                          !!(errors && errors.classification)
                                        }
                                        prompt={errors.classification?.message}
                                      >
                                        <option value="" disabled={true}>
                                          {getInputPlaceholder(
                                            PlaceholderType.SelectInput
                                          )}
                                        </option>
                                        {jobTitles.map(
                                          (jobType: LovDto, index) => {
                                            return (
                                              <option
                                                key={`classification-${index}`}
                                                value={jobType.lovCode}
                                              >
                                                {jobType.lovValue}
                                              </option>
                                            )
                                          }
                                        )}
                                      </ClinSelect>
                                    )}
                                  />
                                  <ClinSpacer height={ClinTheme.space[4]} />
                                </Col>
                              </Row>
                              <ClinSpacer height={ClinTheme.space[4]} />
                              <Row>
                                <Col xs={12} lg={9}>
                                  <ClinText
                                    as="h1"
                                    variant={TypographyVariant.Paragraph}
                                  >
                                    {t('onboarding:first_step_description')}
                                  </ClinText>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9}>
                                  {Object.values(OnboardingCommonJobs).map(
                                    (ta, index) =>
                                      index < 5 ? (
                                        <ClinChip
                                          key={`chip-${index}`}
                                          isInteractive={true}
                                          onClick={() => {
                                            trigger('classification')
                                            handleChipClicked &&
                                              handleChipClicked(
                                                ta,
                                                'classification'
                                              )
                                          }}
                                        >
                                          {ta}
                                        </ClinChip>
                                      ) : (
                                        <></>
                                      )
                                  )}
                                </Col>
                              </Row>
                              <ClinSpacer height={ClinTheme.space[8]} />
                              <Row
                                style={{
                                  display: showLicenceNumber ? 'block' : 'none'
                                }}
                              >
                                <Col xs={12} lg={9}>
                                  <ClinTextInput
                                    {...register('licenseNumber')}
                                    id="license-number"
                                    label={t('common:labels.license_number')}
                                    width="100%"
                                    hasError={
                                      !!(errors && errors.licenseNumber)
                                    }
                                    prompt={errors.licenseNumber?.message}
                                  />
                                  <ClinSpacer height={ClinTheme.space[8]} />
                                </Col>
                              </Row>
                              <ClinGroup>
                                <StyledButtonLoadingContainer>
                                  <ClinButton
                                    type="button"
                                    variant="primary"
                                    onClick={async () => {
                                      await trigger('licenseNumber').then(
                                        () => {
                                          ;((showLicenceNumber &&
                                            watchLicense !== '') ||
                                            !showLicenceNumber) &&
                                            handleNextActiveStep &&
                                            handleNextActiveStep()
                                        }
                                      )
                                    }}
                                  >
                                    {/* Mobile */}
                                    <Visible xs>
                                      {t('onboarding:button_next')}
                                    </Visible>
                                    {/* Tablet and up */}
                                    <Hidden xs>
                                      {t('onboarding:button_next')}
                                    </Hidden>
                                  </ClinButton>
                                  {isSubmitting && (
                                    <StyledLoadingSpinner>
                                      <ClinSpinner />
                                    </StyledLoadingSpinner>
                                  )}
                                </StyledButtonLoadingContainer>
                              </ClinGroup>
                              <ClinSpacer
                                height={ClinTheme.space[9]}
                              ></ClinSpacer>
                              {showDebug && (
                                <>
                                  <pre>
                                    {JSON.stringify(formValues, null, 2)}
                                  </pre>
                                  {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
                                  <pre>{JSON.stringify(user, null, 2)}</pre>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {activeStep === 1 && (
                        <>
                          <Row style={{ alignItems: 'center' }}>
                            <Col xs={12} lg={9}>
                              <ClinText
                                as="h1"
                                variant={TypographyVariant.LargerParagraph}
                                color={ClinTheme.colors.primary}
                              >
                                {t('onboarding:second_step_title')}
                              </ClinText>
                            </Col>
                            <Col>
                              <ClinPaginationDots
                                totalIndex={totalSteps}
                                activeIndex={activeStep}
                                alt={paginationAltMode}
                              ></ClinPaginationDots>
                            </Col>
                          </Row>
                          <ClinSpacer />

                          {specialisms && (
                            <>
                              <Row>
                                <Col xs={12} lg={9}>
                                  <Controller
                                    name="specialism"
                                    control={control}
                                    render={({
                                      field: { onChange, value }
                                    }) => (
                                      <ClinSelect
                                        id="specialism"
                                        label={t('common:labels.specialism')}
                                        width="100%"
                                        name="specialism"
                                        hasError={
                                          !!(errors && errors.specialism)
                                        }
                                        prompt={errors.specialism?.message}
                                        value={value}
                                        onChange={(
                                          changeValue: ChangeEvent<HTMLSelectElement>
                                        ) =>
                                          onChange(
                                            changeValue.currentTarget.value
                                          )
                                        }
                                      >
                                        <option value="" disabled={true}>
                                          {getInputPlaceholder(
                                            PlaceholderType.SelectInput
                                          )}
                                        </option>
                                        {specialisms
                                          .sort((a, b) =>
                                            a.lovValue && b.lovValue
                                              ? a.lovValue.localeCompare(
                                                  b.lovValue
                                                )
                                              : 0
                                          )
                                          .map((specialism: LovDto, index) => {
                                            return (
                                              <option
                                                key={`specialism${index}`}
                                                value={specialism.lovCode}
                                              >
                                                {specialism.lovValue}
                                              </option>
                                            )
                                          })}
                                      </ClinSelect>
                                    )}
                                  />
                                  <ClinSpacer height={ClinTheme.space[4]} />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={9}>
                                  <ClinText
                                    as="h1"
                                    variant={TypographyVariant.Paragraph}
                                  >
                                    {t('onboarding:second_step_description')}
                                  </ClinText>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={9}>
                                  {Object.values(
                                    OnboardingCommonSpecialisms
                                  ).map((ta, index) =>
                                    index < 5 ? (
                                      <ClinChip
                                        key={`chip-${index}`}
                                        isInteractive={true}
                                        onClick={() =>
                                          handleChipClicked &&
                                          handleChipClicked(ta, 'specialism')
                                        }
                                      >
                                        {ta}
                                      </ClinChip>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}

                          <ClinSpacer height={ClinTheme.space[4]} />
                          <ClinGroup>
                            <StyledButtonLoadingContainer>
                              <ClinButton
                                type="button"
                                variant="primary"
                                onClick={() => {
                                  trigger('specialism')
                                  if (watchSpecialism || watchSpecialism !== '')
                                    handleNextActiveStep &&
                                      handleNextActiveStep()
                                }}
                              >
                                {/* Mobile */}
                                <Visible xs>
                                  {t('onboarding:button_next')}
                                </Visible>
                                {/* Tablet and up */}
                                <Hidden xs>
                                  {t('onboarding:button_next')}
                                </Hidden>
                              </ClinButton>
                              {isSubmitting && (
                                <StyledLoadingSpinner>
                                  <ClinSpinner />
                                </StyledLoadingSpinner>
                              )}
                            </StyledButtonLoadingContainer>
                          </ClinGroup>
                          <ClinSpacer height={ClinTheme.space[9]}></ClinSpacer>
                          {showDebug && (
                            <>
                              <pre>{JSON.stringify(formValues, null, 2)}</pre>
                              {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
                              <pre>{JSON.stringify(user, null, 2)}</pre>
                            </>
                          )}
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <Row style={{ alignItems: 'center' }}>
                            <Col xs={12} lg={9}>
                              <ClinText
                                as="h1"
                                variant={TypographyVariant.LargerParagraph}
                                color={ClinTheme.colors.primary}
                              >
                                {t('onboarding:third_step_title')}
                              </ClinText>
                            </Col>
                            <Col>
                              <ClinPaginationDots
                                totalIndex={totalSteps}
                                activeIndex={activeStep}
                                alt={paginationAltMode}
                              ></ClinPaginationDots>
                            </Col>
                            <ClinSpacer height={30}></ClinSpacer>
                            <Col>
                              <ClinText
                                variant={TypographyVariant.Paragraph}
                                fontWeight={ClinTheme.fontWeights.bold}
                              >
                                {t('onboarding:third_step_description')}
                              </ClinText>
                              <ClinText
                                variant={TypographyVariant.SmallUI}
                                fontWeight={ClinTheme.fontWeights.normal}
                              >
                                {t('onboarding:third_step_subtitle')}
                              </ClinText>
                            </Col>
                          </Row>
                          <ClinSpacer height={32}></ClinSpacer>
                          <Controller
                            name="languagePreference"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <ClinSelect
                                id="languagePreference"
                                label={t('onboarding:choose_language')}
                                width="100%"
                                value={value}
                                onChange={(
                                  changeValue: ChangeEvent<HTMLSelectElement>
                                ) => {
                                  onChange(changeValue.currentTarget.value)
                                  handleChangedLanguage(
                                    changeValue.currentTarget.value
                                  )
                                }}
                                hasError={
                                  !!(errors && errors.languagePreference)
                                }
                                prompt={errors.languagePreference?.message}
                              >
                                {languageOptions.map(
                                  (
                                    option: ICustomSelectOption,
                                    index: number
                                  ) => {
                                    return (
                                      <option
                                        key={`code-${index}`}
                                        value={option.id}
                                      >
                                        {option.value}
                                      </option>
                                    )
                                  }
                                )}
                              </ClinSelect>
                            )}
                          />
                          <ClinSpacer />
                          <Row>
                            <Col xs={12} lg={12}>
                              <ClinText
                                variant={TypographyVariant.Paragraph}
                                fontWeight={ClinTheme.fontWeights.bold}
                              >
                                {t('onboarding:marketing_step_title')}
                              </ClinText>
                              <ClinText
                                variant={TypographyVariant.SmallUI}
                                fontWeight={ClinTheme.fontWeights.normal}
                              >
                                {t('onboarding:marketing_step_description')}
                              </ClinText>
                            </Col>
                          </Row>

                          <ClinSpacer height={ClinTheme.space[4]} />

                          <ClinButton
                            variant={'linkButton'}
                            onClick={() => handleSelectAll()}
                            style={{
                              fontWeight: 'normal',
                              marginTop: '10px',
                              marginBottom: '10px'
                            }}
                          >
                            {t('common:buttons.select_all')}
                          </ClinButton>

                          <Controller
                            name={`preferences.${0}.optedIn`}
                            control={control}
                            defaultValue={
                              preferences ? preferences[0]?.optedIn : false
                            }
                            render={({ field: { onChange, value } }) => {
                              return (
                                <ClinMarketingPreferenceWithIcon
                                  id="access-program-medicines"
                                  title={t('onboarding:marketing_first_title')}
                                  description={t(
                                    'onboarding:marketing_first_desc'
                                  )}
                                  iconName={ClinIconPathName.Availability}
                                  iconColor={ClinTheme.colors.primaryLight}
                                  checked={value}
                                  onPreferenceChange={(newValue) => {
                                    onChange(newValue)
                                  }}
                                />
                              )
                            }}
                          />
                          <ClinSpacer height={8} />
                          <Controller
                            name={`preferences.${1}.optedIn`}
                            control={control}
                            defaultValue={
                              preferences ? preferences[1]?.optedIn : false
                            }
                            render={({ field: { onChange, value } }) => {
                              return (
                                <ClinMarketingPreferenceWithIcon
                                  id="unlicenced-medicines"
                                  title={t('onboarding:marketing_second_title')}
                                  description={t(
                                    'onboarding:marketing_second_desc'
                                  )}
                                  iconName={ClinIconPathName.TherapeuticArea}
                                  iconColor={ClinTheme.colors.darkGreen}
                                  checked={value}
                                  onPreferenceChange={(newValue) =>
                                    onChange(newValue)
                                  }
                                />
                              )
                            }}
                          />
                          <ClinSpacer height={8} />
                          <Controller
                            name={`preferences.${2}.optedIn`}
                            defaultValue={
                              preferences ? preferences[2]?.optedIn : false
                            }
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <ClinMarketingPreferenceWithIcon
                                  id="shortages"
                                  title={t('onboarding:marketing_third_title')}
                                  description={t(
                                    'onboarding:marketing_third_desc'
                                  )}
                                  iconName={ClinIconPathName.DeliveryAus}
                                  iconColor={ClinTheme.colors.magenta}
                                  checked={value}
                                  onPreferenceChange={(newValue) =>
                                    onChange(newValue)
                                  }
                                />
                              )
                            }}
                          />

                          <ClinSpacer height={8} />
                          <Controller
                            name={`preferences.${3}.optedIn`}
                            control={control}
                            defaultValue={
                              preferences ? preferences[3]?.optedIn : false
                            }
                            render={({ field: { onChange, value } }) => {
                              return (
                                <ClinMarketingPreferenceWithIcon
                                  id="how-to-guides"
                                  title={t('onboarding:marketing_fourth_title')}
                                  description={t(
                                    'onboarding:marketing_fourth_desc'
                                  )}
                                  iconName={ClinIconPathName.Document}
                                  iconColor={ClinTheme.colors.primaryLightest}
                                  checked={value}
                                  onPreferenceChange={(newValue) =>
                                    onChange(newValue)
                                  }
                                />
                              )
                            }}
                          />

                          <ClinSpacer height={ClinTheme.space[7]} />

                          <ClinGroup>
                            <ClinButton
                              variant="primary"
                              disabled={isSubmitting}
                              onClick={() => {
                                handleNextActiveStep && handleNextActiveStep()
                              }}
                            >
                              {/* Mobile */}
                              <Visible xs>
                                {t('onboarding:button_next')}
                              </Visible>
                              {/* Tablet and up */}
                              <Hidden xs>{t('onboarding:button_next')}</Hidden>
                            </ClinButton>
                            <StyledButtonLoadingContainer>
                              <ClinButton
                                variant="ghost"
                                onClick={() => {
                                  handleNextActiveStep && handleNextActiveStep()
                                  handleSkippedQuestion &&
                                    handleSkippedQuestion(true)
                                }}
                              >
                                {/* Mobile */}
                                <Visible xs>
                                  {t('onboarding:skip_question')}
                                </Visible>
                                {/* Tablet and up */}
                                <Hidden xs>
                                  {t('onboarding:skip_question')}
                                </Hidden>
                              </ClinButton>
                              {isSubmitting && (
                                <StyledLoadingSpinner>
                                  <ClinSpinner />
                                </StyledLoadingSpinner>
                              )}
                            </StyledButtonLoadingContainer>
                          </ClinGroup>
                          <ClinSpacer height={ClinTheme.space[9]}></ClinSpacer>
                          {showDebug && (
                            <>
                              <pre>{JSON.stringify(formValues, null, 2)}</pre>
                              {/* <pre>{JSON.stringify(formState, null, 2)}</pre> */}
                              <pre>{JSON.stringify(user, null, 2)}</pre>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </StyledFormContainer>
              </Row>

              <ClinSpacer height={ClinTheme.space[7]} />
            </Container>
          </StyledOnboarding>
        )}

        {activeStep === totalSteps && (
          <StyledOnboarding
            style={{
              minHeight: '100vh',
              padding: '40px',
              backgroundColor: `${ClinTheme.colors.lightGrey}`
            }}
          >
            <Row className="onboarding-container" style={{ margin: '0 auto' }}>
              <Col>
                <ClinLogo
                  style={{ width: '153px', marginBottom: '-2px' }}
                ></ClinLogo>
                <ClinText
                  as="h1"
                  variant={TypographyVariant.H2}
                  fontWeight={ClinTheme.fontWeights.medium}
                  marginBottom={'36px'}
                >
                  {t('onboarding:one_last_thing_title')}
                </ClinText>
                <ClinText
                  as="h2"
                  variant={TypographyVariant.H4}
                  marginBottom={'36px'}
                >
                  {t('onboarding:one_last_thing_desc')}
                </ClinText>

                <OnboardingLineItem
                  itemKey="common:labels.email_address"
                  itemValue={user.contactEmail}
                ></OnboardingLineItem>
                <OnboardingLineItem
                  itemKey="common:labels.first_name"
                  itemValue={user.firstName}
                ></OnboardingLineItem>
                <OnboardingLineItem
                  itemKey="common:labels.last_name"
                  itemValue={user.lastName}
                ></OnboardingLineItem>
                <OnboardingLineItem
                  itemKey="common:labels.preferred_name_optional"
                  itemValue={
                    user.preferredName && user.preferredName !== ''
                      ? user.preferredName
                      : user.firstName
                  }
                ></OnboardingLineItem>
                <OnboardingLineItem
                  itemKey="common:labels.specialism"
                  itemValue={getValues('specialism')}
                ></OnboardingLineItem>
                <OnboardingLineItem
                  itemKey="common:labels.your_job"
                  itemValue={
                    getValues('classification') === 'SITE CO_ORDINATOR'
                      ? 'Site Co-ordinator'
                      : getValues('classification')
                  }
                ></OnboardingLineItem>
                <OnboardingLineItem
                  itemKey="common:labels.license_number"
                  itemValue={getValues('licenseNumber')}
                ></OnboardingLineItem>
                {genericPhone ? (
                  <OnboardingLineItem
                    itemKey="common:labels.phone_number_one"
                    itemValue={`+${genericPhone.countryCode} ${genericPhone.areaCode}  ${genericPhone.phoneNumber}`}
                  ></OnboardingLineItem>
                ) : (
                  user?.phones[0] && (
                    <OnboardingLineItem
                      itemKey="common:labels.phone_number_one"
                      itemValue={`+${user?.phones[0]?.countryCode} ${user?.phones[0]?.areaCode}  ${user?.phones[0]?.phoneNumber}`}
                    ></OnboardingLineItem>
                  )
                )}
                {genericPhone ? (
                  <OnboardingLineItem
                    itemKey="common:extension_optional"
                    itemValue={
                      genericPhone.phoneExtension
                        ? genericPhone.phoneExtension
                        : '-'
                    }
                  ></OnboardingLineItem>
                ) : (
                  '-'
                )}
                <ClinSpacer height={ClinTheme.space[9]} />
              </Col>

              <ClinGroup className="onboarding-buttons-wrap">
                <ClinButton
                  variant="ghost"
                  type="submit"
                  onClick={() => {
                    handleRedirectToAccDetails && handleRedirectToAccDetails()
                  }}
                >
                  {/* Mobile */}
                  <Visible xs>{t('onboarding:take_me_to_details')}</Visible>
                  {/* Tablet and up */}
                  <Hidden xs>{t('onboarding:take_me_to_details')}</Hidden>
                </ClinButton>
                <Visible xs>
                  <ClinSpacer height={ClinTheme.space[2]}></ClinSpacer>
                </Visible>
                <StyledButtonLoadingContainer>
                  <ClinButton
                    type="submit"
                    variant="primary"
                    onClick={() => {
                      handleRedirectToHome && handleRedirectToHome()
                    }}
                  >
                    {/* Mobile */}
                    <Visible xs>{getSubmitButtonText()}</Visible>
                    {/* Tablet and up */}
                    <Hidden xs>{getSubmitButtonText()}</Hidden>
                  </ClinButton>
                  {isSubmitting && (
                    <StyledLoadingSpinner>
                      <ClinSpinner />
                    </StyledLoadingSpinner>
                  )}
                </StyledButtonLoadingContainer>
              </ClinGroup>
            </Row>
          </StyledOnboarding>
        )}
      </form>
    </>
  )
}
