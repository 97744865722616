import * as React from 'react'
import { ClinTheme } from '../../ClinTheme'

import { StyledVerticalSpacer } from './ClinSpacer.styles'
import { IDefaultStyleProps } from '../../types'

export interface IVerticalSpacerStyles {
  /** The height of the spacer */
  height?: number | string
  /** Displays a border middle */
  hasBorder?: boolean
  /** Set z-index */
  zIndex?: string
  /** Custom color */
  color?: string

  width?: string

  top?: string
}

interface IVerticalSpacerProps
  extends IDefaultStyleProps,
    IVerticalSpacerStyles {}

export const ClinSpacer: React.FC<IVerticalSpacerProps> = ({
  className = '',
  height = ClinTheme.space[3],
  hasBorder = false,
  zIndex = 'unset',
  color = '',
  width = '',
  top = ''
}) => (
  <StyledVerticalSpacer
    className={`clin-vertical-spacer ${className}`}
    height={height}
    hasBorder={hasBorder}
    zIndex={zIndex}
    color={color}
    width={width}
    top={top}
  />
)
