import {
  ProductItemAvailabilityStatus,
  SourceSystem,
  productStatusSortOrder
} from '../../../constants'
import {
  AvailableWarehouse,
  IAugmentedCatalogItemDto
} from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import {
  DefaultWarehouseDto,
  StockDto,
  WarehouseDto
} from '../../../types/swaggerTypes'
import _ from 'lodash'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'

export interface ICatalogItemDto extends IAugmentedCatalogItemDto {
  warehouseCodes: string[]
  warehouses: WarehouseDto[] | undefined
}

/**
 * Get the stock/warehouse with the most stock in reserve
 */
export const getBestStockedWarehouse = (stocks: StockDto[]): StockDto => {
  return stocks.reduce<StockDto>(
    (prev: StockDto, curr: StockDto) =>
      curr.reservableStock > prev.reservableStock ? curr : prev,
    stocks[0]
  )
}

/**
 * Calculate availability score for sorting
 */
const getAvailabilityScore = (
  item: IAugmentedCatalogItemDto,
  portfolioCountryCode: string
): number => {
  if (
    !item.availableStatus ||
    item.availableStatus === ProductItemAvailabilityStatus.HIDDEN
  ) {
    return -1
  }

  const statusIndex = productStatusSortOrder.indexOf(item.availableStatus)
  const baseScore = (productStatusSortOrder.length - statusIndex) * 1000

  // Add score from default warehouse stock
  const defaultWarehouse = getDefaultWarehouseCode(portfolioCountryCode)
  const defaultWarehouseStock =
    item.stockLevels?.find((stock) => stock.warehouse === defaultWarehouse)
      ?.reservableStock || 0

  return baseScore + defaultWarehouseStock + (item.reservableStock || 0)
}

/**
 * Sort catalog items by availability status and stock levels
 */
export const sortCatalogItemsByAvailability = (
  a: IAugmentedCatalogItemDto,
  b: IAugmentedCatalogItemDto,
  portfolioCountryCode: string
): number => {
  const scoreA = getAvailabilityScore(a, portfolioCountryCode)
  const scoreB = getAvailabilityScore(b, portfolioCountryCode)

  return scoreB - scoreA
}
/**
 * Australia-specific sort for catalog items
 */
export const sortCatalogItemsByAvailabilityAus = (
  a: IAugmentedCatalogItemDto,
  b: IAugmentedCatalogItemDto,
  portfolioCountryCode: string
): number => {
  const scoreA = getAvailabilityScore(a, portfolioCountryCode)
  const scoreB = getAvailabilityScore(b, portfolioCountryCode)

  if (scoreA !== scoreB) {
    return scoreB - scoreA
  }
  // If scores are equal but both items have stock, prioritize higher stock
  if (a.reservableStock && b.reservableStock) {
    return b.reservableStock - a.reservableStock
  }

  // Prioritize items with defined stock over undefined
  if (a.reservableStock && !b.reservableStock) return -1
  if (!a.reservableStock && b.reservableStock) return 1

  // If neither has stock, prioritize Sage items for AU
  if (
    a.item.sourceSystem === SourceSystem.Sage &&
    b.item.sourceSystem !== SourceSystem.Sage
  ) {
    return -1
  }
  if (
    a.item.sourceSystem !== SourceSystem.Sage &&
    b.item.sourceSystem === SourceSystem.Sage
  ) {
    return 1
  }

  return 0
}

/**
 * Get matching default warehouse codes
 */
export const getMatchingDefaultWarehouseCodes = (
  stockLevels: StockDto[],
  portfolioCountryCode: string,
  defaultWarehouses?: DefaultWarehouseDto[]
): string[] => {
  if (!defaultWarehouses?.length) {
    return [getDefaultWarehouseCode(portfolioCountryCode)]
  }

  const augmentedWarehouses = defaultWarehouses.map((w) => ({
    ...w,
    warehouse: w.warehouseCode
  }))

  const matchingCodes = _.intersectionBy(
    augmentedWarehouses,
    stockLevels,
    'warehouse'
  ).map((w) => w.warehouseCode)

  return matchingCodes.length
    ? matchingCodes
    : [getDefaultWarehouseCode(portfolioCountryCode)]
}

/**
 * Get default warehouse code by country
 */
export const getDefaultWarehouseCode = (
  portfolioCountryCode: string
): string => {
  switch (portfolioCountryCode) {
    case CountryAlphaCodes.Australia:
      return AvailableWarehouse.T07
    case CountryAlphaCodes.Ireland:
      return AvailableWarehouse.C06
    default:
      return AvailableWarehouse.C02
  }
}
